import React, {useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {Form, Button} from 'react-bootstrap'
import {message} from 'antd'

import AuthLayout from "../../layouts/AuthLayout"
import {handleError} from '../../helpers'
import {reset} from './services'

function Reset(props) {
	const [loading, setLoading] = useState(false)
   const { handleSubmit, register, errors } = useForm()
	
	const onSubmit = values => {
      setLoading(true)
      values.token = props.match.params.token
		reset(values)
			.then(() => {
            message.success('Su clave fue cambiada exitosamente.')
            props.history.push('/auth/login')
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<AuthLayout title="Cambiar Contraseña" description="Ingrese su nueva contraseña">
			<Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Control 
						name="password" 
						placeholder="Escriba su nueva contrasña" 
						type="password"
						ref={register({ required: true })}
					/>
					{errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
				</Form.Group>

            <Form.Group>
					<Form.Control 
						name="password_confirmation" 
						placeholder="Confirme su nueva contrasña" 
						type="password"
						ref={register({ required: true })}
					/>
					{errors.password_confirmation && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
				</Form.Group>

				<div className="mt-3">
					<Button 
						className="btn-block waves-effect waves-light" 
						type="submit"
						disabled={loading}
					>
						{!loading ? 'Cambiar Contraseña' : 'Solicitando contraseña...' }
					</Button>
				</div>

				<div className="mt-4 text-center">
					<Link to="/auth/login" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Iniciar Sesión</Link>
				</div>
			</Form>
		</AuthLayout>
	)
}

export default withRouter(Reset)