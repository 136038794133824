import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import UsersList from './UsersList'
import UserProfile from './UserProfile'

export default function Users() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/users" component={UsersList} />
				<Route exact path="/admin/users/:id" component={UserProfile} />
				
				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}