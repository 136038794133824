import s4lmsapi from "../../../services/s4lmsapi";

export const getComments = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/comments`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getComment = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/comments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createComment = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/comments', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateComment = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/comments/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteComment = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/comments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)