import React, { } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { updateAnswer, createAnswer } from '../Admin/Answers/services'
import { handleError } from '../../helpers'

export default function AnswerModal({ visible, onClose, reload, question }) {
	const { handleSubmit, register, errors } = useForm()
	const auth = useSelector(state => state.auth)
	
	if(!question) return null

	const onSubmit = values => {
		if(question.answer){
			updateAnswer(question.answer.id, values)
				.then(() => reload())
				.catch(error => handleError(error))
		} else {
			createAnswer({ ...values, question_id: question.id, user_id: auth.user.id })
				.then(() => reload())
				.catch(error => handleError(error))
		}
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>{question.name}</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Control
						as="textarea"
						rows={4}
						defaultValue={question.answer ? question.answer.answer : ''}
						name="answer"
						ref={register({ required: true })}
					/>
					{ errors.answer && <Form.Text className="text-warning">Debes escribir una respuesta para guardar</Form.Text> }
					<Button color="primary" className="full-width mt-2" size="sm" outline>
						{ !question.answer ? 'Guardar Respuesta' : 'Actualizar Respuesta' }
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}