import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Spinner, Row, Card, CardBody, Col } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import { getCourse } from '../Admin/Courses/services'
import { handleError } from '../../helpers'

const CourseProfile = ({ courseId, history }) => {
	const [course, setCourse] = useState(null)

	useEffect(() => {
		!course && getCourse(courseId, { include: 'activeModules' })
			.then(res => setCourse(res.data.data))
			.catch(error => handleError(error))
	}, [courseId, course])

	if(!course) return <Spinner />

	if(!course.is_allowed) history.push('/catalog')

	return (
		<React.Fragment>
			<PageTitle title={course.name} directory="Cursos Online" />
			{ course.logo && (
				<div className="text-center mt-2 mb-5">
					<img src={course.logo} alt="Logo" width="280px" />
				</div>
			)}
			{course.active_modules.length > 0 ? (
				<Row>
					{ course.active_modules.map( (module, index) => (
						<Col key={module.id} lg="4" sm="6">
							<Card>
								<CardBody>
									<h6>Módulo {index}</h6>
									<Title>{module.name}</Title>
									<DescriptionContainer>
										<p className="mb-0 text-muted small">{module.description}</p>
									</DescriptionContainer>
									<Link to={`/courses/modules/${module.id}`}>
										<ButtonLink className="btn btn-outline-primary">Entrar al Módulo</ButtonLink>
									</Link>
								</CardBody>
							</Card>
						</Col>
					)) }
				</Row>
			) : (
				<Card>
					<CardBody>
						<Empty description="El curso no tiene módulos" />
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}

export default withRouter(CourseProfile)

const Title = styled.h2`
	color: #646c9a;
	font-size: 16px;
	font-weight: 500;
`

const DescriptionContainer = styled.div`
	margin-bottom: 15px;
`

const ButtonLink = styled.div`
	width: 100%;
	text-align: center;
	font-size: 13px;
	padding: 7px 10px;
	&:hover {
		color: #fff;
	}
`