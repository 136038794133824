import s4lmsapi from "../../services/s4lmsapi";

export const getResults = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post(`/search`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)
