import React, {useState, useEffect} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import ContentSearchBar, { NoContentSelected } from '../../../components/ContentSearchBar'
import NewAttachment from './partials/NewAttachment'
import AttachmentsTable from './partials/AttachmentsTable'
import { getAttachments } from './services'
import { handleError } from '../../../helpers'

export default function AttachmentsList() {
	const [type, setType] = useState('lessons')
	const [contentId, setContentId] = useState(null)
	const [attachments, setAttachments] = useState(null)
	const [currentContentId, setCurrentContentId] = useState(null)

	useEffect(() => {
		contentId && !attachments && getAttachments({ 
			"filter[attachable_type]": type,
			"filter[attachable_id]": contentId
		})
			.then(res => {
				setAttachments(res.data.data)
				setCurrentContentId(contentId)
			})
			.catch(error => handleError(error))
	}, [contentId, attachments, type])

	useEffect(() => {
		if(currentContentId !== contentId) 
			setAttachments(null)
	}, [contentId, currentContentId])

	return (
		<React.Fragment>
			<PageTitle title="Descargables y Links" directory="Actividades" />
			<ContentSearchBar type={type} setType={setType} setContentId={setContentId} />
			<Row>
				<Col md="4" sm="6">					
					<Card>
						<CardBody>
							<CardTitle>Agregar Descargable/Link</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<NewAttachment 
									type={type}
									contentId={contentId}
									reload={()=>setAttachments(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Listado de Descargables/Links</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<AttachmentsTable 
									attachments={attachments} 
									reload={() => setAttachments(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}