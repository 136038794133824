import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Empty, message } from 'antd'
import { createUserCustomValue, updateUserCustomValue } from '../Admin/Users/services'
import { handleError } from '../../helpers'

export default function AddDetailModal({ visible, onClose, reload, userId, fields, customValues }) {
	const { handleSubmit, errors, register } = useForm()
	const [currentField, setCurrentField] = useState('')

	const onSubmit = values => {
		let isCurrent = customValues.filter(cv => cv.custom_field_id === currentField.id)
		if(isCurrent.length > 0){
			updateUserCustomValue(userId, isCurrent[0].id, values)
				.then(() => {
					message.success('Datos actualizados exitosamente')
					reload()
				})
				.catch(error => handleError(error))
		} else {
			createUserCustomValue(userId, values)
				.then(() => {
					message.success('Datos actualizados exitosamente')
					reload()
				})
				.catch(error => handleError(error))
		}
	}

	if(!visible) return null

	if(fields.length === 0) 
		return <Empty description="Pronto habilitaremos esta funcionalidad." />

	const getInputType = () => {
		switch (currentField.type) {
			case 'date': return 'date'
			case 'integer': return 'number'
			default: return 'text'
		}
	}

	const getDefaultValue = () => {
		let customValue = customValues.filter(cv => cv.custom_field_id === currentField.id)
		if(customValue.length > 0)
			return customValue[0][`${currentField.type}_value`]
		else return ''
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Perfil en Comunidad</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Control
							as="select"
							name="custom_field_id"
							value={currentField.id}
							onChange={e => {
								let newField = fields.filter(f => f.id === parseInt(e.target.value))
								setCurrentField(newField[0])
							}}
							ref={register({ required: true })}
						>
							<option value="" disabled={currentField ? true : false}>:: Elige la información que deseas editar ::</option>
							{ fields.map(field => <option key={field.id} value={field.id}>{field.name}</option>) }
						</Form.Control>
						{ errors.custom_field_id && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					{ currentField && (
						<React.Fragment>
							<Form.Group>
								{ currentField.type !== 'json' ? (
									<Form.Control
										as={currentField.type === 'string' ? 'textarea' : 'input'}
										rows={3}
										name={`${currentField.type}_value`}
										defaultValue={getDefaultValue()}
										type={getInputType()}
										ref={register({ required: currentField.required ? true : false })}
									/>
								) : (
									<Form.Control
										as="select"
										name="json_value"
										defaultValue={getDefaultValue()}
										ref={register({ required: currentField.required ? true : false })}
									>
										<option value="">::Elige una opción::</option>
										{ currentField.options.map( (opt,i) => <option key={i} value={opt}>{opt}</option>) }
									</Form.Control>
								)}
								{ errors[`${currentField.type}_value`] && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<div className="mt-2">
								<Button color="primary" className="full-width" outline>
									Actualizar Información
								</Button>
							</div>
						</React.Fragment>
					)}
				</Form>
			</ModalBody>
		</Modal>
	)
}