import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateTask } from '../services'
import { handleError } from '../../../../helpers'

export default function EditTask({ visible, onClose, reload, task }) {
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		updateTask(task.id, values)
			.then(() => {
				message.success('Pregunta actualizada exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	if(!task) return null

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Editar Reto</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Reto <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							defaultValue={task.name}
							ref={register({ required: true })}
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Texto de Ayuda</Form.Label>
						<Form.Control 
							as="textarea"
							name="description"
							defaultValue={task.description}
							ref={register({})}
						/>
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								type="integer"
								name="order"
								defaultValue={task.order}
								ref={register({ required: true })}
							/>
							{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="active"
								defaultValue={task.active ? 1 : 0}
								ref={register({})}
							>
								<option value={1}>Publicado</option>
								<option value={0}>Borrador</option>
							</Form.Control>
						</Form.Group>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary">Actualizar Reto</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}