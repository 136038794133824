import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import {Container, Description} from './ProductsCatalog'
import PageTitle from '../../../components/PageTitle'
import QuestionsImg from '../../../assets/images/undraw/questions.svg'
import DownloadsImg from '../../../assets/images/undraw/downloads.svg'
import TasksImg from '../../../assets/images/undraw/todos.svg'

export default function ActivitiesCatalog() {

	const activities = [
		{
			image: QuestionsImg,
			title: 'Preguntas Claves',
			description: 'Preguntas que ayudan a profundizar o aplicar el contenido.',
			btnText: 'Ver preguntas',
			btnLink: '/admin/questions',
			secBtnText: 'Ver Respuestas',
			secBtnLink: '/admin/answers',
		},
		{
			image: DownloadsImg,
			title: 'Descargables y Links',
			description: 'Formatos y adjuntos claves para el aprendizaje.',
			btnText: 'Ver descargables',
			btnLink: '/admin/attachments'
		},
		{
			image: TasksImg,
			title: 'Retos de Clase',
			description: 'Asigna tareas a tus alumnos y recibe respuesta aquí mismo.',
			btnText: 'Ver retos',
			btnLink: '/admin/tasks',
			secBtnText: 'Ver Respuestas',
			secBtnLink: '/admin/solutions',
		}
	]

	return (
		<React.Fragment>
			<PageTitle title="Actividades" directory="Administrador" />
			<Row>
				{ activities.map((activity,i) => (
					<Col md="4" lg="3" key={i}>
						<Container>
							<img src={activity.image} alt={activity.title} height="100px" />
							<h4 className="mt-2">{activity.title}</h4>
							<Description>{activity.description}</Description>
							<Link to={activity.btnLink}>
								<Button color="primary" size="sm">{activity.btnText}</Button>
							</Link>
							{ activity.secBtnLink && (
								<Link to={activity.secBtnLink} className="mt-2">
									<Button color="secondary" size="sm">{activity.secBtnText}</Button>
								</Link>
							)}
						</Container>
					</Col>
				)) }
			</Row>
		</React.Fragment>
	)
}