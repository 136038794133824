import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap'
import { Empty, Table } from 'antd'
import moment from 'moment'

import EmptyImg from '../../../assets/images/undraw/nodata.svg'
import { getLogs } from '../../Admin/Logs/services'
import { handleError } from '../../../helpers'

export default function LastContentWidget() {
	const [logs, setLogs] = useState(null)
	const auth = useSelector(state => state.auth)

	useEffect(()=>{
		!logs && getLogs({
			"filter[user_id]": auth.user.id,
			"filter[type]": 'content_completed',
			sort: "-created_at",
			include: 'user,content',
			per_page: 5
		})
			.then(res => setLogs(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<Card>
				<CardHeader>Tus clases más recientes</CardHeader>
				<CardBody>
					{ logs ? (
						<React.Fragment>
							{ logs.length > 0 ? (
								<Table
									columns={columns}
									rowKey={record => record.id}
									dataSource={logs}
									size="middle"
									pagination={false}
									
								/>
							) : (
								<Empty
									image={EmptyImg}
									description="Aún no completas clases"
								/>
							)}
						</React.Fragment>
					) : (
						<Spinner size="sm" />
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const columns = [
	{
		title: 'Clase',
		dataIndex: 'content',
		render: (text, record) => (
			<Link to={`/${record.content.content_type}/${record.content.id}`}>
				{text.name} <small>[{record.content.product_name}]</small>
			</Link>)
	},
	{
		title: 'Finalizada El',
		dataIndex: 'payload',
		render: text => moment(text.completedAt).format('YYYY-MM-DD h:mm A')
	}
]