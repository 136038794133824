import React, {useState, useEffect} from 'react'
import { Button, Card, CardBody, Spinner, Row, Col } from 'reactstrap'
import { Empty, Divider, message } from 'antd'

import PageTitle from '../../../components/PageTitle'
import { handleError } from '../../../helpers'
import { getLessons, deleteLesson } from './services'

import EmptyImg from '../../../assets/images/undraw/empty.svg'
import SearchBar from '../../../components/SearchBar'
import NewLesson from './partials/NewLesson'
import LessonCard from './partials/LessonCard'
import EditLesson from './partials/EditLesson'
import LessonsTable from './partials/LessonsTable'

export default function LessonsList() {
	const [lessons, setLessons] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterTerm, setFilterTerm] = useState('')
	const [openNewModal, setOpenNewModal] = useState(false)
	const [editLesson, setEditLesson] = useState(null)
	const [viewType, setViewType] = useState('cards')

	useEffect(() => {
		!lessons && getLessons({ [`filter[${filterType}]`]: filterTerm })
			.then(res => setLessons(res.data.data))
			.catch(error => handleError(error))
	})

	const handleDelete = id => {
		deleteLesson(id)
			.then(() => {
				message.success('Clase eliminada exitosamente')
				setLessons(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<PageTitle title="Clases en Video" directory="Contenido">
				<Button color="" onClick={()=>setViewType('table')} className={viewType === 'table' ? 'text-primary' : ''}>
					<i className="fa fa-table" /> Tabla
				</Button>
				<Divider type="vertical" />
				<Button color="" onClick={()=>setViewType('cards')} className={viewType === 'cards' ? 'text-primary' : ''}>
					<i className="fa fa-address-card" /> Tarjetas
				</Button>
				<Button color="primary" className="btn-topbar ml-3" onClick={()=>setOpenNewModal(true)}>
					Crear Clase en Video<i className="fa fa-plus ml-2" />
				</Button>
			</PageTitle>
			<Card>
				<CardBody>
					<SearchBar 
						type={filterType} setType={setFilterType} 
						term={filterTerm} setTerm={setFilterTerm} 
						handleSearch={()=>setLessons(null)}
					>
						<option value="name">Buscar en nombre: </option>
						<option value="description">Buscar en descripción: </option>
					</SearchBar>
				</CardBody>
			</Card>
			{ lessons ? (
				<React.Fragment>
					{ lessons.length > 0 ? (
						<Row>
							{ viewType === 'cards' && lessons.map(lesson => (
								<Col lg="3" md="4" sm="6" key={lesson.id}>
									<LessonCard 
										lesson={lesson} 
										handleEdit={()=>setEditLesson(lesson)}
										handleDelete={() => handleDelete(lesson.id)}
									/>
								</Col>
							))}
							{ viewType === 'table' && (
								<Col sm="12">
									<LessonsTable 
										lessons={lessons}
										handleEdit={lesson => setEditLesson(lesson)}
										handleDelete={id => handleDelete(id)}
									/> 
								</Col>
							)}
						</Row>
					) : (
						<Card>
							<CardBody>
								<Empty description="Aún no tienes clases creadas" image={EmptyImg} />
							</CardBody>
						</Card>
					)}
				</React.Fragment>
			) : <div className="text-center my-5"><Spinner /></div>}
			<NewLesson 
				visible={openNewModal} 
				onClose={() => setOpenNewModal(false)}
				reload={() => {setOpenNewModal(false); setLessons(null)}}
			/>
			<EditLesson 
				visible={editLesson ? true : false}
				onClose={() => setEditLesson(null)}
				reload={() => { setEditLesson(null); setLessons(null) }}
				lesson={editLesson}
			/>
		</React.Fragment>
	)
}