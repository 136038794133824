import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd'
import styled from 'styled-components'

import { storeFile } from '../../Files/services'
import { handleError } from '../../../../helpers'
import { updateAttachment } from '../services';

export default function UploadFileModal({ visible, onClose, attachment, reload }) {
	const [imageLoading, setImageLoading] = useState(false)
	const [fileUrl, setFileUrl] = useState(null)	
	const [filename, setFilename] = useState(null)

	useEffect(() => {
		visible && !fileUrl && !imageLoading && 
			setFileUrl(attachment.file_url ? `${attachment.file_url}?nocache=${Math.random().toString()}` : '')
	}, [attachment, fileUrl, imageLoading, visible])

	const beforeUpload = file => {
		setFilename(file.name)

		if ((file.size / 1024 / 1024) > 5){
		   message.warning('El archivo no puede superar los 5 MB');
			return false;
		}

		return true;
	}

	const handleUpload = data => {
		const formData = new FormData();
		formData.append('file', data.file);
		formData.append('directory', 'attachments');
		formData.append('key', filename);

		setImageLoading(true)
		setFileUrl(null)

		storeFile(formData)
			.then(res => {
				updateAttachment(attachment.id, { file: filename })
					.then(() => {
						setImageLoading(false)
						onClose()
						reload()
						message.success('Archivo guardado exitosamente')
					})
					.catch(error => handleError(error))
			})
			.catch(error => handleError(error))
	}
  
	const uploadButton = (
      <React.Fragment>
        {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Subir Archivo</div>
      </React.Fragment>
	);

	const handleClose = () => {
		setFileUrl(null)
		onClose()
	}

	return (
		<Modal isOpen={visible} toggle={handleClose}>
			<ModalHeader toggle={handleClose}>Subir Archivo</ModalHeader>
			<ModalBody>
				<Row>
					<Col sm="3">
						<Upload
							name="file"
							listType="picture-card"
							showUploadList={false}
							beforeUpload={beforeUpload}
							customRequest={handleUpload}
						>
							{uploadButton}
						</Upload>
					</Col>
					<Col>
						<ul>
							<li><strong>Descargable:</strong> {attachment && attachment.name}</li>
							<li><strong>Archivo actual:</strong> {fileUrl ? <Link href={fileUrl} target="_blank" rel="noopener noreferrer">Abrir Archivo</Link> : 'No tiene adjunto'}</li>
						</ul>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	)
}

const Link = styled.a`
	color: blue;
`