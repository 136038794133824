import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Row, Col, Spinner } from 'reactstrap'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { Empty } from 'antd'

import SearchBox from './partials/SearchBox';
import { handleError } from '../../helpers'
import { getResults } from './services'

export default function SearchResults(props) {
	const term = decodeURI(props.match.params.term)
	const [results, setResults] = useState(null)
	const [currentTerm, setCurrentTerm] = useState(term)
	const [newTerm, setNewTerm] = useState(term)

	useEffect(() => {
		!results && getResults({term: currentTerm})
			.then(res => setResults(res.data))
			.catch(error => handleError(error))
	}, [results, currentTerm])

	if(!results) return <Spinner />

	const handleSearch = () => {
		setCurrentTerm(newTerm)
		setResults(null)
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody className="full-width">
					<InputGroup>
						<InputGroup.Append>
							<InputGroup.Text>
								<span className="bx bx-search-alt mr-1"/>
								Resultados para:
							</InputGroup.Text>
						</InputGroup.Append>
						<Form.Control
							placeholder="Buscar contenido..."
							value={newTerm ? newTerm : ''}
							onChange={e => setNewTerm(e.target.value)}
							onKeyDown={e => e.key === 'Enter' && handleSearch()}
						/>
						<InputGroup.Prepend>
							<Button variant="primary">Buscar Contenido</Button>
						</InputGroup.Prepend>						
					</InputGroup>
				</CardBody>
			</Card>
			<Row>
				<Col>
					<Card>
						<CardHeader>Clases Encontradas: <span className="small">Mostrando los primeros 10 resultados</span></CardHeader>
						<CardBody>
							{ results.lessons.length > 0 ? (
								<React.Fragment>
									{ results.lessons.map(lesson => (
										<SearchBox
											key={lesson.id} 
											link={`/lessons/${lesson.id}`}
											term={currentTerm}
											name={lesson.content.name}
											description={lesson.content.description}
											context={lesson.product_name}
										/>
									)) }
									<div className="text-center mt-3">
										<p className="small text-muted mb-0"><em>Fin de resultados</em></p>
									</div>
								</React.Fragment>
							) : <Empty description="No se encontraron clases" />}
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardHeader>Cursos Encontrados: <span className="small">Mostrando los primeros 10 resultados</span></CardHeader>
						<CardBody>
							{ results.courses.length > 0 ? (
								<React.Fragment>
									{ results.courses.map(course => (
										<SearchBox
											key={course.id} 
											link={`/courses/${course.id}`}
											term={currentTerm}
											name={course.name}
											description={course.description}
										/>
									)) }
									<div className="text-center mt-3">
										<p className="small text-muted mb-0"><em>Fin de resultados</em></p>
									</div>
								</React.Fragment>
							) : <Empty description="No se encontraron cursos" />}
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}