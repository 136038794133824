import React, {useState, useEffect} from 'react'
import { Button, Card, CardBody, Spinner, Row, Col } from 'reactstrap'
import { Empty } from 'antd'

import PageTitle from '../../../components/PageTitle'
import { handleError } from '../../../helpers'
import { getCourses } from './services'
import EmptyImg from '../../../assets/images/undraw/empty.svg'
import SearchBar from '../../../components/SearchBar'
import NewCourse from './partials/NewCourse'
import CourseCard from './partials/CourseCard'
import EditCourse from './partials/EditCourse'

export default function CoursesList() {
	const [courses, setCourses] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterTerm, setFilterTerm] = useState('')
	const [openNewModal, setOpenNewModal] = useState(false)
	const [editCourse, setEditCourse] = useState(null)

	useEffect(() => {
		!courses && getCourses({ [`filter[${filterType}]`]: filterTerm })
			.then(res => setCourses(res.data.data))
			.catch(error => handleError(error))
	}, [courses, filterType, filterTerm])

	return (
		<React.Fragment>
			<PageTitle title="Cursos Online" directory="Info Productos">
				<Button color="primary" className="btn-topbar" onClick={()=>setOpenNewModal(true)}>
					Crear Curso<i className="fa fa-plus ml-2" />
				</Button>
			</PageTitle>
			<Card>
				<CardBody>
					<SearchBar 
						type={filterType} setType={setFilterType} 
						term={filterTerm} setTerm={setFilterTerm} 
						handleSearch={()=>setCourses(null)}
					>
						<option value="name">Buscar en nombre: </option>
						<option value="description">Buscar en descripción: </option>
					</SearchBar>
				</CardBody>
			</Card>
			{ courses ? (
				<React.Fragment>
					{ courses.length > 0 ? (
						<Row>
							{ courses.map(course => (
								<Col md="4" key={course.id}>
									<CourseCard 
										course={course} 
										handleEdit={() => setEditCourse(course)}
									/>
								</Col>
							)) }
						</Row>
					) : (
						<Card>
							<CardBody className="text-center my-5">
								<Empty description="Aún no tienes cursos creados" image={EmptyImg} />
							</CardBody>
						</Card>
					)}
				</React.Fragment>
			) : <Spinner />}
			<NewCourse 
				visible={openNewModal} 
				onClose={() => setOpenNewModal(false)}
				reload={() => { setCourses(null); setOpenNewModal(false)}}
			/>
			<EditCourse 
				visible={editCourse ? true : false} 
				onClose={() => setEditCourse(null)}
				reload={() => { setCourses(null); setEditCourse(null)}}
				course={editCourse}
			/>
		</React.Fragment>
	)
}