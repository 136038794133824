import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Row, Col, CardTitle, Spinner, Button } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import CourseModules from './partials/CourseModules'
import ModuleChapters from './partials/ModuleChapters'
import { handleError } from '../../../helpers'
import { getCourse } from './services'

export default function CourseProfile(props) {
	const courseId = props.match.params.id
	const [course, setCourse] = useState(null)
	const [moduleSelected, setModuleSelected] = useState(null)
	
	useEffect(()=>{
		!course && getCourse(courseId, {})
			.then(res => setCourse(res.data.data))
			.catch(error => handleError(error))
	}, [course, courseId])

	if(!course) return <Spinner />	

	return (
		<React.Fragment>
			<PageTitle title="Módulos y Capítulos" directory="Cursos Online" />
			<Card>
				<CardBody className="py-3">
					<div className="float-right">
						<Link to="/admin/courses">
							<Button color="primary" outline className="mt-1">
								<i className="fa fa-arrow-left" /> Ver Cursos
							</Button>
						</Link>
					</div>
					<p className="text-muted mb-0">Editando curso:</p>
					<h5 className="mb-0">{course.name}</h5>
				</CardBody>
			</Card>
			<Row>
				<Col sm="5">
					<Card>
						<CardBody>
							<CardTitle>Módulos <small>Un curso tiene módulos</small></CardTitle>
							<Divider className="mt-0 mb-3" />
							<CourseModules 
								courseId={course.id}
								reload={()=>setModuleSelected(null)}
								handleSelect={module => setModuleSelected(module)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Cápitulos <small>Un módulo tiene cápitulos</small></CardTitle>
							<Divider className="mt-0 mb-3" />
							<ModuleChapters 
								module={moduleSelected} 
								close={() => setModuleSelected(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}