import React, {useState, useEffect} from 'react'
import { Spinner, Row } from 'reactstrap'
import { Empty } from 'antd'

import Layout from "../../layouts/SideLayout"
import PageTitle from '../../components/PageTitle'
import CourseCard from './CourseCard'

import { getCourses } from '../Admin/Courses/services'
import { handleError } from '../../helpers'

export default function Catalog() {
	const [courses, setCourses] = useState(null)

	useEffect(() => {
		!courses && getCourses()
			.then(res => setCourses(res.data.data))
			.catch(error => handleError(error))
	}, [courses])

	return (
		<Layout>
			<PageTitle title="Catálogo de Contenidos" directory="LMS" />
			{ courses ? (
				<React.Fragment>
					{ courses.length > 0 ? (
						<Row>
							{ courses.map(course => (								
								<CourseCard course={course} />
							))}
						</Row>
					) : <Empty description="No se encontraron cursos" />}
				</React.Fragment>
			) : <Spinner />}
		</Layout>
	)
}