import React, { useEffect } from "react"
import {useSelector, useDispatch} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"
import styled from 'styled-components'
import { Spinner } from "reactstrap"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Helmet } from "react-helmet"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Dashboard from "./containers/Dashboard"
import Admin from "./containers/Admin"
import Catalog from "./containers/Catalog"
import Courses from "./containers/Courses"
import Lesson from "./containers/Lesson"
import Notebook from "./containers/Notebook"
import Profile from "./containers/Profile"
import Community from "./containers/Community"
import Search from "./containers/Search"
import { getPublicSettings } from "./containers/Admin/Settings/services"
import { updateSettings } from "./containers/Admin/Settings/actions"
import { handleError } from "./helpers"

import "antd/dist/antd.less";
import "./assets/scss/theme.scss"

Bugsnag.start({
	apiKey: '377baa7bb7017bb27e6b23d83ea1484e',
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
	  .createErrorBoundary(React)	

export default function App() {	
	const dispatch = useDispatch()
	const settings = useSelector(state => state.settings)
	const auth = useSelector(state => state.auth)

	useEffect(()=>{
		!settings.primary_color && getPublicSettings()
			.then(res => dispatch(updateSettings({ 
				primary_color: '#022fbf', ...res.data.data 
			})))
			.catch(error => handleError(error))
	}, [settings, dispatch])

	if(!settings.primary_color) 
		return <div className="text-center mt-4"><Spinner /></div>
	
	return (
		<ErrorBoundary>
			<Theme primaryColor={settings.primary_color}>
				<Helmet>
					<title>{settings.app_name}</title>
					<link rel="icon" href={settings.app_favicon} />
				</Helmet>
				<Switch>
					<Redirect exact path="/" to="/dashboard" />

					<Route path="/auth" component={Authentication} />
					{!auth.token && <Redirect to="/auth/login" />}
					
					{ auth.user && auth.user.is_admin && <Route path="/admin" component={Admin} />}

					<Route path="/dashboard" component={Dashboard} />
					<Route path="/catalog" component={Catalog} />
					<Route path="/courses" component={Courses} />
					<Route path="/lessons/:id" component={Lesson} />
					<Route path="/notebook" component={Notebook} />
					<Route path="/profile" component={Profile} />
					<Route path="/community" component={Community} />
					<Route path="/search" component={Search} />

					<Route component={Error404} />
				</Switch>
			</Theme>
		</ErrorBoundary>
	);	
}

const Theme = styled.div`
	& .btn-primary, 
	.bg-primary,
	.bkg-primary,
	.btn-outline-primary:hover {
		background-color: ${props => props.primaryColor};
	}
	& a,
	.text-primary,
	.txt-primary,
	#sidebar-menu ul li a i,	
	.btn-outline-primary:hover,
	.btn-outline-primary,
	.footer  {
		color: ${props => props.primaryColor};
	}
	& .btn-outline-primary,
	.btn-outline-primary,
	.btn-primary  {
		border-color: ${props => props.primaryColor};
	}
	& .bg-soft-primary {
		background-color: ${props => props.primaryColor}20 !important;
	}
	& .btn-primary, 
	.btn-primary:hover,
	.btn-outline-primary:hover {
		color: #fff;
	}
`