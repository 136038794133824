import React from 'react'
import { InputGroup, Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { Tooltip } from 'antd'
import styled from 'styled-components'

export default function SearchBar({ type, setType, term, setTerm, handleSearch, children }) {
	const handleClean = () => {
		setTerm('')
		handleSearch()
	}

	return (
		<React.Fragment>
			<Container>
				<InputGroup>
					<Form.Control
						as="select"
						value={type}
						onChange={e => setType(e.target.value)}
					>
						{children}
					</Form.Control>
					<Form.Control
						placeholder="Escriba aquí..."
						value={term}
						onKeyDown={e => e.key === 'Enter' && handleSearch()}
						onChange={e => setTerm(e.target.value)}
					/>
					<InputGroup.Append>
						<Tooltip title="Buscar">
							<Button color="primary" onClick={handleSearch}>
								<i className="fa fa-search"></i>
							</Button>
						</Tooltip>
					</InputGroup.Append>
					<InputGroup.Append>
						<Tooltip title="Limpiar Filtros">
							<Button color="light" onClick={handleClean} style={{ border: '1px solid #ccc' }}>
								<i className="fa fa-trash-alt"></i>
							</Button>
						</Tooltip>
					</InputGroup.Append>
				</InputGroup>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	width: 100%;
	max-width: 550px;
`