import React, {useState, useEffect} from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'

import EditField from './EditField'
import NewField from './NewField'
import FieldsTable from './FieldsTable'
import { getCustomFields } from '../services'
import { handleError } from '../../../../helpers'

export default function UserCustomFields() {
	const [fields, setFields] = useState(null)
	const [editField, setEditField] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(()=>{
		!fields && getCustomFields({ "filter[model]": 'users' })
			.then(res => setFields(res.data.data))
			.catch(error => handleError(error))
	})	

	return (
		<React.Fragment>
			<Card>
				<CardHeader>Campos Personalizado de Usuarios</CardHeader>
				<CardBody>
					<FieldsTable 
						fields={fields} 
						reload={()=>setFields(null)} 
						handleEdit={record => setEditField(record)}
					/>
					<div className="text-left">
						<Button color="primary" onClick={()=>setShowNewModal(true)}>
							Agregar Campo Personalizado
						</Button>
					</div>
				</CardBody>
			</Card>
			<NewField
				visible={showNewModal}
				onClose={()=>setShowNewModal(false)}
				reload={()=>{ setFields(null); setShowNewModal(false) }}
			/>
			<EditField
				visible={editField ? true : false}
				onClose={()=>setEditField(false)}
				reload={()=>{ setFields(null); setEditField(null) }}
				field={editField}
			/>
		</React.Fragment>
	)
}