import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import SolutionsList from './SolutionsList'

export default function Solutions() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/solutions" component={SolutionsList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}