import React, {useState, useEffect} from 'react'
import { Spinner, Button } from 'reactstrap'
import { Empty, Tooltip, Popconfirm, message } from 'antd'
import styled from 'styled-components'

import EmptyImg from '../../assets/images/undraw/researching.svg'
import { getCustomFields } from '../Admin/Settings/services'
import { getUserCustomValues, deleteUserCustomValue } from '../Admin/Users/services'
import AddDetailModal from './AddDetailModal'
import { handleError } from '../../helpers'

export default function CustomDetails({ userId }) {
	const [fields, setFields] = useState(null)
	const [values, setValues] = useState(null)
	const [openModal, setOpenModal] = useState(false)

	useEffect(()=>{
		!fields && getCustomFields({ "filter[model]": "users" })
			.then(res => setFields(res.data.data))
			.catch(error => handleError(error))
	}, [fields])

	useEffect(()=>{
		!values && getUserCustomValues(userId, {})
			.then(res => setValues(res.data.data))
			.catch(error => handleError(error))
	}, [values, userId])

	const handleDelete = id => {
		deleteUserCustomValue(userId, id)
			.then(() => {
				message.success('Dato eliminado exitosamente')
				setValues(null)
			})
	}

	if(!fields || !values) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ values.length > 0 ? (
				<React.Fragment>
					{ values.map(val => (
						<DetailCard key={val.id}>
							<div className="float-right">
								<Tooltip title="Borrar">
									<Popconfirm
										title="¿Seguro deseas borrarlo?"
										okText="Borrar"
										cancelText="Cancelar"
										okButtonProps={{ danger:true }}
										onConfirm={() => handleDelete(val.id)}
									>
										<Button color="danger" size="sm" outline>
											<i className="fa fa-trash" />
										</Button>
									</Popconfirm>
								</Tooltip>
							</div>
							<h6 className="small mb-1">{val.formated.name}</h6>
							<p className="mb-0">{val.formated.value}</p>
						</DetailCard>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					image={EmptyImg}
					description="No tienes datos publicados en la comunidad"
				/>
			)}
			<div className="text-center mt-3">
				<Button color="primary" outline size="sm" onClick={()=>setOpenModal(true)}>
					Actualizar Mi Perfil de Comunidad
				</Button>
			</div>
			<AddDetailModal 
				visible={openModal}
				onClose={()=>setOpenModal(false)}
				reload={()=>{ setValues(null); setOpenModal(false) }}
				userId={userId}
				fields={fields}
				customValues={values}
			/>
		</React.Fragment>
	)
}

const DetailCard = styled.div`
	padding: 10px 10px 5px;
	background-color: #f9f9fc;
	margin-bottom: 5px;
`