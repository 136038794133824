import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { createAttachment } from '../services'
import { handleError } from '../../../../helpers'

export default function NewAttachment({ type, contentId, reload }) {
	const { handleSubmit, register, errors, reset } = useForm()
	const [attachmentType, setAttachmentType] = useState('link')

	const onSubmit = values => {	
		values.attachable_type = type
		values.attachable_id = contentId
		createAttachment(values)
			.then(() => {
				message.success('Recurso creado exitosamente.')
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group>
				<Form.Label>Tipo de Recurso <span className="txt-primary">*</span></Form.Label><br />
				<Form.Check 
					inline 
					label="Descargable" 
					type="radio"
					checked={attachmentType === 'download'} 
					onChange={e => setAttachmentType(e.target.value ? 'download' : 'link')} />
				<Form.Check 
					inline 
					label="Link Externo" 
					type="radio"
					checked={attachmentType === 'link'} 
					onChange={e => setAttachmentType(e.target.value ? 'link' : 'download')} />
			</Form.Group>			
			{ attachmentType === 'link' ? (
				<Form.Group>
					<Form.Label>Link Externo <span className="txt-primary">*</span></Form.Label>
					<Form.Control 
						name="link"
						placeholder="Ingrese la url del link externo"
						ref={register({ required: true })}
					/>
					{ errors.link && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			) : (
				<Form.Group>
					<Form.Label>Archivo (URL) <span className="txt-primary">Opcional</span></Form.Label>
					<Form.Control 
						name="link"
						placeholder="Ingrese una url si no desea adjuntar archivo"
						ref={register({ required: false })}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Nombre <span className="txt-primary">*</span></Form.Label>
				<Form.Control 
					name="name"
					placeholder="Escriba aquí el nombre del descargable o link"
					ref={register({ required: true })}
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Texto de Ayuda</Form.Label>
				<Form.Control 
					as="textarea"
					name="description"
					placeholder="Escriba aquí una descripción. Opcional."
					ref={register({})}
				/>
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
					<Form.Control 
						type="integer"
						name="order"
						defaultValue={10}
						ref={register({ required: true })}
					/>
					{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
					<Form.Control 
						as="select"
						name="active"						
						ref={register({})}
					>
						<option value={1}>Publicada</option>
						<option value={0}>Borrador</option>
					</Form.Control>
				</Form.Group>
			</Row>
			<Button color="primary" type="submit">
				{attachmentType === 'link' ? 'Crear Link Externo' : 'Crear Descargable'}			
			</Button>
		</Form>
	)
}