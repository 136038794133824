import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import { getAnswers } from '../Admin/Answers/services'
import { handleError } from '../../helpers'
import SearchBar from '../../components/SearchBar'

export default function UserAnswers({ user }) {
	const [answers, setAnswers] = useState(null)
	const [filterType, setFilterType] = useState('answer')
	const [filterTerm, setFilterTerm] = useState('')

	useEffect(()=>{
		!answers && getAnswers({
			include: "question",
			"filter[user_id]": user.id,
			[`filter[${filterType}]`]: filterTerm
		})
			.then(res => setAnswers(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<SearchBar 
				type={filterType} setType={setFilterType} 
				term={filterTerm} setTerm={setFilterTerm} 
				handleSearch={()=>setAnswers(null)}
			>
				<option value="answer">Buscar en respuesta: </option>
				<option value="question.name">Buscar en pregunta: </option>
			</SearchBar>
			<AnswersContainer>
				{ answers ? (
					<React.Fragment>
						{ answers.length > 0 ? (
							<React.Fragment>
								{ answers.map(answer => (
									<AnswerCard key={answer.id}>
										<p className="small mb-1 text-muted">Actualizada el: {moment(answer.updated_at).format('L')}</p>
										<p className="mb-2">{answer.answer}</p>
										<h6 className="small">
											{answer.question.name}
										</h6>
									</AnswerCard>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontron preguntas" /> }
					</React.Fragment>
				) : <Spinner size="sm" /> }
			</AnswersContainer>
			{ answers && answers.length > 0 && (
				<div className="text-center small text-muted mt-2">
					Mostrando un total de: {answers.length} pregunta{answers.length > 1 ? 's' : ''}
				</div>
			)}
		</React.Fragment>
	)
}

const AnswersContainer = styled.div`
	max-height: 400px;
	overflow-y: scroll;
	background-color: #f9f9fc;
	padding: 15px;
	margin: 10px 0px;
`
const AnswerCard = styled.div`
	background-color: #fff;
	padding: 10px 10px 5px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	margin-bottom: 10px;
`