import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Row, Col, Media, Button } from 'reactstrap'
import { Divider, Tooltip } from 'antd'
import moment from 'moment'

import { UserDetails, UserDetail } from '../CommunityProfiles'

export default function ProfileCard({ user, onDetails }) {
	const settings = useSelector(state => state.settings)

	return (
		<Card className="mini-stats-wid">
			<CardBody>
				<Media>
					<div className="mini-stat-icon avatar-md  bkg-primary mr-3">
						{ user.profile_url ? (
							<img src={user.profile_url} alt="Profile" width="100%" />
						):(
							<span className="avatar-title bkg-primary">
								<i className="bx bx-user font-size-24"></i>
							</span>
						)}
					</div>
					<Media body className="mt-2">
						<p className="text-muted small mb-1 mt-1">
							Miembro desde el{" "}
							{moment(user.created_at).format('L')}
						</p>
						<h4 className="mb-0">{user.name}</h4>
					</Media>
				</Media>
				<UserDetails scroll={true}>
					{ user.custom_values.map(detail => (
						<UserDetail key={detail.id}>
							<span className="small text-muted">{detail.formated.name}: </span><br />
							{detail.formated.value}
						</UserDetail>
					))}
				</UserDetails>
				<Divider className="my-2" />
				<Row>
					<Col className="text-center" style={{ borderRight: '1px solid #f9f9fc' }}>
						<Tooltip title="Ver Detalles">
							<Button color="primary" size="sm" outline onClick={()=>onDetails(user)}>
								<i className="fa fa-id-card" />
							</Button>
						</Tooltip>
					</Col>
					{ settings.community_allow_email === "1" && (
						<Col className="text-center">
							<Tooltip title="Enviar Email">
								<a href={`mailto:${user.email}`}>
									<Button color="primary" size="sm" outline>
										<i className="fa fa-envelope" />
									</Button>
								</a>
							</Tooltip>
						</Col>
					)}
				</Row>
			</CardBody>
		</Card>
	)
}