import React, {useState, useEffect} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import ContentSearchBar, { NoContentSelected } from '../../../components/ContentSearchBar'
import NewQuestion from './partials/NewQuestion'
import QuestionsTable from './partials/QuestionsTable'
import { getQuestions } from './services'
import { handleError } from '../../../helpers'

export default function QuestionsList() {
	const [type, setType] = useState('lessons')
	const [contentId, setContentId] = useState(null)
	const [questions, setQuestions] = useState(null)
	const [currentContentId, setCurrentContentId] = useState(null)

	useEffect(() => {
		contentId && !questions && getQuestions({ 
			"filter[questionable_type]": type,
			"filter[questionable_id]": contentId
		})
			.then(res => {
				setQuestions(res.data.data)
				setCurrentContentId(contentId)
			})
			.catch(error => handleError(error))
	}, [contentId, questions, type])

	useEffect(() => {
		if(currentContentId !== contentId) 
			setQuestions(null)
	}, [contentId, currentContentId])

	return (
		<React.Fragment>
			<PageTitle title="Preguntas Claves" directory="Actividades" />
			<ContentSearchBar type={type} setType={setType} setContentId={setContentId} />
			<Row>
				<Col md="4" sm="6">					
					<Card>
						<CardBody>
							<CardTitle>Agregar Pregunta Clave</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<NewQuestion 
									type={type}
									contentId={contentId}
									reload={()=>setQuestions(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Listado de Preguntas Claves</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<QuestionsTable 
									questions={questions} 
									reload={() => setQuestions(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}