import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Badge } from 'reactstrap'
import styled from 'styled-components'
import { Empty, Tooltip, Popconfirm, message, Table, Divider } from 'antd'

import NewContent from './NewContent'
import { getContents, deleteContent } from '../services'
import { handleError } from '../../../../helpers'
import EditContent from './EditContent'

export default function ChapterContents({ visible, onClose, chapter }) {
	const [contents, setContents] = useState(null)	
	const [showNewModal, setShowNewModal] = useState(false)
	const [editContent, setEditContent] = useState(null)
	
	useEffect(() => {
		chapter && !contents && getContents({ 
			"filter[product_type]": "chapters",
			"filter[product_id]": chapter.id,
		})
			.then(res => setContents(res.data.data))
			.catch(error => handleError(error))
	}, [chapter, contents])

	const handleClose = () => {
		setContents(null)
		onClose()
	}

	const handleDelete = id => {
		deleteContent(id)
			.then(() => {
				message.success('Contenido desasociado exitosamente')
				setContents(null)
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order'
		},
		{
			title: 'Nombre',
			dataIndex: 'content',
			render: text => text.name
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? <Badge color="success">Publicado</Badge> : <Badge color="warning">Borrador</Badge>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button size="sm" color="primary" outline onClick={()=>setEditContent(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar Contenido">
						<Popconfirm
							title="Se eliminará todos los comentarios y notas existentes"
							cancelText="Cancelar"
							okText="Eliminar"
							okButtonProps={{ danger: true }}
							onConfirm={() => handleDelete(record.id)}
						>
							<Button color="danger" outline size="sm">
								<i className="fa fa-unlink" />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	if(!chapter) return null

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose} size="lg">
				<ModalHeader toggle={handleClose}>Contenidos del Cápitulo</ModalHeader>
				<ModalBody>
					<SelectedTitle className="bg-light">
						<p className="text-muted mb-0">Contenidos del Capítulo:</p>
						<h5 className="mb-0">{chapter.name}</h5>
					</SelectedTitle>
					{ contents ? (
						<React.Fragment>
							{ contents.length > 0 ? (
								<Table 
									dataSource={contents}
									rowKey={record => record.id}
									size="middle"
									columns={columns}
								/>
							) : (
								<Empty description="No tiene contenidos asociados a este capítulo" />
							)}
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={()=>setShowNewModal(true)}>Agregar Contenido</Button>
				</ModalFooter>
			</Modal>
			<NewContent
				visible={showNewModal}
				onClose={() => setShowNewModal(false)}
				reload={() => {setShowNewModal(false); setContents(null)}}
				productType="chapters"
				productId={chapter.id}
			/>
			<EditContent 
				visible={editContent ? true : false}
				onClose={()=>setEditContent(null)}
				reload={() => { setContents(null); setEditContent(null) }}
				content={editContent}
			/>
		</React.Fragment>
	)
}

const SelectedTitle = styled.div`
	text-align: center;
	padding: 15px 15px;
	margin-bottom: 20px;
`