import React from 'react'
import { Spinner, Button } from 'reactstrap'
import { message, Table, Empty, Tooltip, Divider, Popconfirm } from 'antd'
import moment from 'moment'

import { deleteCustomField } from '../services'
import { handleError } from '../../../../helpers'

export default function FieldsTable({ fields, reload, handleEdit }) {

	const onDelete = id => {
		deleteCustomField(id)
			.then(()=>{
				message.success('Campo eliminado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	if(!fields) return <Spinner size="sm" />

	const columns = [
		{
			title: 'Campo',
			dataIndex: 'name'
		},
		{
			title: 'Restricción',
			dataIndex: 'required',
			render: text => text ? 'Requerido' : 'Opcional'
		},
		{
			title: 'Creado el',
			dataIndex: 'created_at',
			render: text => moment(text).format('L')
		},
		{
			title: 'Acciones',
			render: (txt, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button color="info" outline size="sm" onClick={()=>handleEdit(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Se eliminará todos los datos"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={()=>onDelete(record.id)}
						>
							<Button color="danger" outline size="sm">
								<i className="fa fa-trash" />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<Table
			dataSource={fields}
			rowKey={record => record.id}
			columns={columns}
			size="middle"
			expandable={{
				expandedRowRender: record => <p style={{ margin: 0 }}> <strong>Descripción:</strong> {record.description}</p>
			}}
			locale={{ emptyText: <Empty description="No se encontraron campos personalizados" /> }}
		/>
	)
}