import React from 'react';
import { Card, CardBody, Media } from "reactstrap";

export default function StatCard({ title, count, iconClass }) {
   return (
      <Card className="mini-stats-wid">
         <CardBody>
            <Media>
               <Media body>
                  <p className="text-muted font-weight-medium mb-0">{title}</p>
                  <h2 className="mb-0">{count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
               </Media>

               <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bkg-primary">
                  <span className="avatar-title bkg-primary">
                     <i className={"bx " + iconClass + " font-size-24"}></i>
                  </span>
               </div>
            </Media>
         </CardBody>
      </Card>
   )
}