import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import AnswersList from './AnswersList'

export default function Answers() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/answers" component={AnswersList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}