import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Spinner } from 'reactstrap'
import { Divider } from 'antd'

import StatsRow from './partials/StatsRow'
import CompletedLog from './partials/CompletedLog'
import NewFeatures from './partials/NewFeatures'
import { getAdminDashboard } from './services'
import { handleError } from '../../../helpers'

export default function AdminDashboard() {
	const settings = useSelector(state => state.settings)
	const [dashboard, setDashboard] = useState(null)

	useEffect(()=>{
		!dashboard && getAdminDashboard()
			.then(res => setDashboard(res.data))
			.catch(error => handleError(error))
	}, [dashboard])	

	return (
		<React.Fragment>
			<div className="mt-2">
				<div className="float-right">
					<h3 className="txt-primary mt-2" onClick={()=>setDashboard(null)}>
						<i className="fa fa-sync" />
					</h3>
				</div>
				<h2>Hola, {settings.app_name}</h2>
				<h6 className="text-muted">Administrador</h6>
			</div>
			<Divider />
			{ dashboard ? (
				<React.Fragment>
					<StatsRow stats={dashboard.stats} />
					<Row>
						<Col md="8">
							<CompletedLog />
						</Col>
						<Col>
							<NewFeatures 
								tip={dashboard.tip}
								features={dashboard.new_features}
								version={dashboard.current_version}
							/>
						</Col>
					</Row>
				</React.Fragment>
			) : <Spinner />}
		</React.Fragment>
	)
}