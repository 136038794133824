import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { updateUser } from '../Admin/Users/services'
import { message } from 'antd'
import { handleError } from '../../helpers'

export default function PasswordUpdate({ userId }) {
	const { handleSubmit, register, errors, setError, clearError, reset } = useForm()

	const onSubmit = values => {		
		if(values.password !== values.password_confirmation) setError('password_confirmation')
		else clearError('password_confirmation')

		updateUser(userId, values)
			.then(() => {
				message.success('Contraseña actualizada exitosamente')
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Nueva Contraseña <span className="txt-primary">*</span></Form.Label>
					<Form.Control
						name="password"
						type="password"
						ref={register({ required:true })}
					/>
					{ errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Confirmar Nueva Contraseña <span className="txt-primary">*</span></Form.Label>
					<Form.Control
						name="password_confirmation"
						type="password"
						ref={register({ required:true })}
					/>
					{ errors.password_confirmation && <Form.Text className="text-warning">Las constraseñas no coinciden.</Form.Text> }
				</Form.Group>
				<Button color="primary" outline size="sm">Cambiar Contraseña</Button>
			</Form>
		</React.Fragment>
	)
}