import s4lmsapi from "../../../services/s4lmsapi";

export const getUsers = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/users`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getUser = (id, params) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/users/${id}`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createUser = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/users', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateUser = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/users/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteUser = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/users/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getUserCustomValues = (userId, params) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/users/${userId}/custom_values`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getUserCustomValue = (userId, id) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/users/${userId}/custom_values/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createUserCustomValue = (userId, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post(`/users/${userId}/custom_values`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateUserCustomValue = (userId, id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/users/${userId}/custom_values/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteUserCustomValue = (userId, id) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/users/${userId}/custom_values/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)