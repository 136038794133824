import React, { useState } from "react";
import {Container} from 'reactstrap'

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

function MainLayout({ children, navbar }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 993 ? true : false)
  const [sidebar, setSidebar] = useState(window.innerWidth > 992 ? true : false)
  
  window.onresize = function(event) {
      if(window.innerWidth < 993 && !isMobile){ setIsMobile(true) }
      if(window.innerWidth > 992 && isMobile){ setIsMobile(false) }
      if(window.innerWidth < 993 && sidebar){ setSidebar(false) }
  };

  if(sidebar){
    document.body.classList.add("sidebar-enable")
  } else {
    document.body.classList.remove("sidebar-enable")
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header isMobile={isMobile} toggleMenuCallback={()=>setSidebar(!sidebar)}  />
        { sidebar && (
          <Sidebar 
            theme="dark"
            navbar={navbar}
          />
        )}
        <div className={sidebar ? 'main-content' : ''}>
          <div className="page-content">
            <Container fluid>
              {children}
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default MainLayout;
