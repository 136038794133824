import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Select } from 'antd'

import { updateCustomField } from '../services'
import { handleError } from '../../../../helpers'

export default function EditField({ visible, onClose, reload, field }) {
	const { handleSubmit, register, errors, setValue } = useForm()

	const onSubmit = values => {
		values.model = 'users'
		updateCustomField(field.id, values)
			.then(()=>{
				message.success('Campo actualizado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	if(!field) return null

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Campo Personalizado</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre del Campo</Form.Label>
						<Form.Control 
							name="name"
							defaultValue={field.name}
							ref={register({ required: true })}							
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Texto de Ayuda</Form.Label>
						<Form.Control 
							name="description"
							defaultValue={field.description}
							ref={register({ required: true })}							
						/>
						{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					{ field.type === 'json' && (
                  <Form.Group>
                     <Form.Label>Opciones</Form.Label>
                     <input type="hidden" name="options" defaultValue={JSON.stringify(field.options)} ref={register({ required: true })} />
                     <Select 
                        mode="tags" 
                        style={{ width: '100%' }} 
                        placeholder="Escriba las opciones" 
                        onChange={values => setValue('options', JSON.stringify(values))}
                        defaultValue={field.options}
                     />
                     { errors.options && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
                  </Form.Group>
               )}
					<Form.Group>
						<Form.Label>Requerido</Form.Label>
						<Form.Control
							as="select"
							name="required"
							defaultValue={field.required ? 1 : 0}
							ref={register({ required: true })}
						>
							<option value={0}>No es requerido</option>
							<option value={1}>Si es requerido</option>
						</Form.Control>
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<Button color="primary">Actualizar Campo</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}