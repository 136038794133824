import React from 'react'
import { Row, Col, Button, Card, CardFooter } from 'reactstrap'
import styled from 'styled-components'
import { Divider, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

export default function CourseCard({ course, handleEdit }) {
	return (
		<React.Fragment>
			<Card>
				<Container>
					<h5>{course.name}</h5>
					<Description>{course.description}</Description>					
				</Container>
				<CardFooter className="bg-white pt-0">
					<Divider className="mt-0 mb-2" />
					<Row>
						<Col className="text-center py-1" style={{ borderRight: '1px solid #f8f8f8' }}>
							<Tooltip title="Editar">
								<Button color="light" size="sm" onClick={handleEdit}>
									<i className="fa fa-edit" />
								</Button>
							</Tooltip>
						</Col>
						<Col className="text-center py-1">
							<Tooltip title="Módulos y Categorías">
								<Link to={`/admin/courses/${course.id}`}>
									<Button color="light" size="sm">
										<i className="fa fa-boxes" />
									</Button>
								</Link>								
							</Tooltip>
						</Col>
						{/* <Col className="text-center py-1" style={{ borderLeft: '1px solid #f8f8f8' }}>
							<Tooltip title="Editar Logo">
								<Button color="light" size="sm">
									<i className="fa fa-image" />
								</Button>
							</Tooltip>
						</Col> */}
					</Row>
				</CardFooter>
			</Card>			
		</React.Fragment>
	)
}

export const Container = styled.div`
	padding: 20px 0px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`
export const Description = styled.p`
	text-align: center;
	font-size: 11px;
	color: #555;
	padding-left: 25px;
	padding-right: 25px;
`