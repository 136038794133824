import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Divider } from 'antd'
import styled from 'styled-components'

import SearchEntity from './SearchEntity'

export default function ContentSearchBar({ type, setType, setContentId }) {
	return (
		<React.Fragment>
			<Card className="mb-3">
				<CardBody className="px-3 py-3">
					<CardTitle>Contenido Asociado</CardTitle>
					<Divider className="mt-0 mb-3" />
					<Row>
						<Form.Group className="mb-2" as={Col} md="4">
							<Form.Control
								as="select"
								value={type}
								onChange={e => setType(e.target.value)}
							>
								<option value="lessons">Clase en Video</option>
							</Form.Control>
						</Form.Group>
						<Form.Group className="mb-0" as={Col} md="8">
							<SearchEntity 
								style={{ width: '100%' }}
								resourceType="lessons"
								setResourceId={setContentId}
							/>
							<Form.Text className="text-muted">Busque el contenido por nombre</Form.Text>
						</Form.Group>
					</Row>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

export const NoContentSelected = () => (
	<NoSelected>
		<p className="text-muted mb-0">
			<i className="fa fa-bell mr-2" />
			Elige el contenido asociado primero
		</p>
	</NoSelected>
)

const NoSelected = styled.div`
	border: 2px dashed #e8e8e8;
	padding: 15px 15px;
`