import React from 'react';
import { useSelector } from 'react-redux'

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Divider } from 'antd';

const UserNavbar = (props) => {
	const settings = useSelector(state => state.settings)
	return (	
		<React.Fragment>
			<div id="sidebar-menu">
				<ul className="metismenu list-unstyled" id="side-menu">
					<li className="menu-title">BIENVENIDO</li>
					<li>
						<Link to="/dashboard">
							<i className="bx bx-home"></i>Dashboard
						</Link>
					</li>
					<Divider className="mb-4" />
					<li>
						<Link to="/catalog">
							<i className="bx bx-book-heart"></i>Contenidos
						</Link>
					</li>
					<li>
						<Link to="/notebook">
							<i className="bx bx-notepad"></i>Cuaderno Digital
						</Link>
					</li>
					<Divider className="mb-4" />
					{ settings.community_enable === "1" && (
						<li>
							<Link to="/community">
								<i className="bx bx-network-chart"></i>Comunidad
							</Link>
						</li>
					)}
					<li>
						<Link to="/profile">
							<i className="bx bx-user"></i>Mi Perfil
						</Link>
					</li>
				</ul>
			</div>
		</React.Fragment>
	);
}

export default withRouter(UserNavbar);


