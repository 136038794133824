import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateLesson } from '../services'
import { handleError } from '../../../../helpers'

export default function EditLesson({ visible, onClose, reload, lesson }) {
	const { handleSubmit, register, errors } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		updateLesson(lesson.id, values)
			.then(() => {
				message.success('Clase actualizada exitosamente.')
				setLoading(false)
				reload()
			})
			.catch(error => {
				setLoading(false)
				handleError(error)
			})
	}

	if(!lesson) return null

	return (
		<Modal isOpen={visible} toggle={()=>onClose()}>
			<ModalHeader toggle={()=>onClose()}>Actualizar Clase en Video</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre de la clase <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							defaultValue={lesson.name}
							ref={register({ required: true })}
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Descripción de la clase</Form.Label>
						<Form.Control 
							as="textarea"
							name="description"
							defaultValue={lesson.description}
							ref={register({})}
						/>
					</Form.Group>
					<Row>
						<Form.Group as={Col} md="5">
							<Form.Label>Fuente del Video <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="video_source"
								defaultValue={lesson.video_source}
								ref={register({})}
							>
								<option value="vimeo">Vimeo</option>
								<option value="youtube">Youtube</option>
							</Form.Control>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>ID Video <span className="txt-primary">*</span></Form.Label>
							<Form.Control
								name="video_ref"
								defaultValue={lesson.video_ref}
								ref={register({ required: true })}
							/>
						</Form.Group>
					</Row>
					<Form.Group>
						<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
						<Form.Control
							as="select"
							name="active"
							defaultValue={lesson.active}
							ref={register({ required: true })}
						>
							<option value={0}>Borrador</option>
							<option value={1}>Publicado</option>
						</Form.Control>
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						Editar Clase {loading && <Spinner size="sm" />}
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}