import s4lmsapi from "../../../services/s4lmsapi";

export const getAttachments = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/attachments`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getAttachment = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/attachments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createAttachment = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/attachments', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateAttachment = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/attachments/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteAttachment = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/attachments/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)