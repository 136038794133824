import React, {useState, useEffect} from 'react'
import { Select } from 'antd';

import { getLessons } from '../containers/Admin/Lessons/services'
import { handleError } from '../helpers'
import { getResults } from '../containers/Search/services';

export default function SearchEntity({ resourceType, style, setResourceId }) {
   const [data, setData] = useState([])
   const [value, setValue] = useState('')

   useEffect(() => {
      setData([])
      setValue('')
      setResourceId(null)
    }, [resourceType, setResourceId]);

   const search = term => {
      let request;
      switch (resourceType) {
         case 'lessons':
            let params = { "filter[name]": term }
            request = getLessons(params)
            break;

         case 'contents':
            request = getResults({ term, only: 'lessons' })
            break;
            
         default: break;
      }
      if(request) request.then(response => setData(response.data.data)).catch(error => handleError(error))
      else setData([])
   }

   const options = data.map(d => (
      <Select.Option key={d.id} value={d.id}>
         {resourceType !== 'contents' && d.name && d.name}
         {resourceType === 'contents' && `[${d.product.name}] ${d.content.name}`}
      </Select.Option>
   ));

   return (
      <Select
         showSearch
         value={value}
         style={style}
         defaultActiveFirstOption={false}
         placeholder="Buscar por nombre..."
         showArrow={false}
         filterOption={false}
         onSearch={value => value ? search(value) : setData([])}
         onChange={value => { 
            setValue(value)
            setResourceId(value)
         }}
         notFoundContent="Sin resultados"
      >
         { options }
      </Select>
   );
}