import React from 'react'

import { Pagination as AntdPagination } from 'antd'

export default function Pagination({ pagination, reload, updatePagination }) {

   const handleChange = (page, size) => {
      updatePagination({ ...pagination, page, per_page: size })
      reload()
   }

   return (
      <AntdPagination
         className="mt-3 mb-3 text-right"
         total={pagination.total}
         showSizeChanger
         showLessItems={true}
         pageSize={pagination.per_page}
         current={pagination.current_page}
         pageSizeOptions={['15','20','50','100']}
         onChange={handleChange}
         disabled={pagination.total === 0}
      />
   )
}