import React, { useState, useEffect } from 'react'
import { Spinner, Badge } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import { getTasks } from '../Admin/Tasks/services'
import { handleError } from '../../helpers'
import SolutionModal from './SolutionModal'

export default function TasksTab({ content }) {
	const [tasks, setTasks] = useState(null)
	const [solutionTask, setSolutionTask] = useState(null)

	useEffect(()=>{
		!tasks && getTasks({ 
			"filter[taskable_type]": content.content_type,
			"filter[taskable_id]": content.content_id,
			"filter[active]": 1
		})
			.then(res => setTasks(res.data.data))
			.catch(error => handleError(error))
	}, [content, tasks])

	if(!tasks) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ tasks.length > 0 ? (
				<React.Fragment>
					{ tasks.map( (task, index) => (
						<TaskDiv key={task.id} onClick={()=>setSolutionTask(task)}>
							<Badge size="sm" color={task.solution ? 'success' : 'warning'} className="mb-2">
								{task.solution ? 'Respondida' : 'Pendiente'}
							</Badge>
							<h6>{task.name}</h6>
							<p className="text-muted small mb-2">{task.description}</p>							
						</TaskDiv>
					)) }
				</React.Fragment>
			) : <Empty description="No hay preguntas para este contenido" />}
			<SolutionModal 
				visible={solutionTask ? true : false}
				onClose={()=>setSolutionTask(null)}
				reload={()=> { setSolutionTask(null); setTasks(null) }}
				task={solutionTask}
			/>
		</React.Fragment>
	)
}

const TaskDiv = styled.div`
	background-color: #ebedf2;
	padding: 15px;
	margin-bottom: 10px;
	cursor: pointer;
	&:hover {
		background-color: #f9f9fc;
	}
`