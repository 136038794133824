import React, {useState, useEffect} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import SolutionsTable from './partials/SolutionsTable'
import { getSolutions } from './services'
import { handleError } from '../../../helpers'
import TableFilters from './partials/TableFilters'

export default function SolutionsList() {
	const [solutions, setSolutions] = useState(null)	
	const [filterUserId, setFilterUserId] = useState('')
	const [filterTaskId, setFilterTaskId] = useState('')

	useEffect(() => {
		!solutions && getSolutions({ 
			include: 'user,task,task.content',
			"filter[user_id]": filterUserId,
			"filter[task_id]": filterTaskId
		})
			.then(res => {
				setSolutions(res.data.data)
			})
			.catch(error => handleError(error))
	}, [solutions, filterTaskId, filterUserId])

	return (
		<React.Fragment>
			<PageTitle title="Respuestas de Retos" directory="Actividades" />
			<Row>
				<Col md="3" sm="6">					
					<Card>
						<CardBody>
							<CardTitle>Filtrar Resultados</CardTitle>
							<Divider className="mt-0 mb-3" />
							<TableFilters
								filterUserId={filterUserId}
								setFilterUserId={setFilterUserId}
								filterTaskId={filterTaskId}
								setFilterTaskId={setFilterTaskId}
								handleSearch={()=>setSolutions(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Listado de Respuestas</CardTitle>
							<Divider className="mt-0 mb-3" />
							<SolutionsTable 
								solutions={solutions} 
								reload={() => setSolutions(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}