import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Spinner, Button, Badge } from 'reactstrap'
import { Empty, Tooltip, Table, Divider } from 'antd'

import NewChapter from './NewChapter'
import EditChapter from './EditChapter'
import ChapterContents from './ChapterContents'
import { getChapters } from '../services'
import { handleError } from '../../../../helpers'

export default function ModuleChapters({ module, close }) {
	const [chapters, setChapters] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [editChapter, setEditChapter] = useState(null)
	const [chapterContents, setChapterContents] = useState(null)

	useEffect(() => {
		module && getChapters({ "filter[module_id]": module.id })
			.then(res => setChapters(res.data.data))
			.catch(error => handleError(error))
	}, [module])

	const onChange = () => {
		getChapters({ "filter[module_id]": module.id })
			.then(res => setChapters(res.data.data))
			.catch(error => handleError(error))
	}

	if(!module) return (
		<NoSelected className="bg-light">
			<p className="mb-0">
				<i className="fa fa-bell mr-2" />
				Selecciona un módulo para editar sus respectivos capítulos
			</p>
		</NoSelected>
	)

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order'
		},
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => active ? <Badge color="success">Publicado</Badge> : <Badge color="warning">Borrador</Badge>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button size="sm" color="primary" outline onClick={()=>setEditChapter(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Contenido">
						<Button size="sm" color="primary" outline onClick={()=>setChapterContents(record)}>
							<i className="fa fa-boxes" />
						</Button>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	if(!chapters || !module) return <Spinner />

	return (
		<React.Fragment>
			<SelectedTitle className="bg-light">
				<p className="text-muted mb-0">Capítulos del Módulo:</p>
				<h5 className="mb-0">{module.name}</h5>
			</SelectedTitle>
			{ chapters.length > 0 ? (
				<React.Fragment>
					<Table
						columns={columns}
						dataSource={chapters}
						rowKey={record => record.id}						
					/>
				</React.Fragment>
			) : (
				<Empty
					description="No tiene capítulos para este módulo"
				/>
			)}
			<div className="text-left">
				<Button color="primary" onClick={() => setShowNewModal(true)}>Agregar Capítulo</Button>
			</div>
			<NewChapter 
				visible={showNewModal}
				onClose={() => setShowNewModal(false)}
				reload={() => { setShowNewModal(false); onChange() }}
				moduleId={module.id}
			/>
			<EditChapter
				visible={editChapter ? true : false}
				onClose={() => setEditChapter(null)}
				reload={() => { setEditChapter(null); onChange(); }}
				chapter={editChapter}
			/>
			<ChapterContents 
				visible={chapterContents ? true : false}
				onClose={() => setChapterContents(null)}
				chapter={chapterContents}
			/>
		</React.Fragment>
	)
}

const NoSelected = styled.div`
	border: 2px dashed #e8e8e8;
	padding: 15px 15px;
`

const SelectedTitle = styled.div`
	text-align: center;
	padding: 15px 15px;
	margin-bottom: 20px;
`