import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import QuestionsList from './QuestionsList'

export default function Questions() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/questions" component={QuestionsList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}