import s4lmsapi from "../../../services/s4lmsapi";

export const getLessons = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/lessons`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getLesson = (id, params) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/lessons/${id}`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createLesson = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/lessons', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateLesson = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/lessons/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteLesson = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/lessons/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)