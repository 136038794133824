import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AttachmentsList from './AttachmentsList'

export default function Attachments() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/attachments" component={AttachmentsList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}