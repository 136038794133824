import { 
   USER_LOGIN, 
   USER_LOGOUT,
   USER_UPDATE
} from "./actions"

const INITIAL_STATE = {}

const authReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case USER_LOGIN:
         return { ...state, ...action.payload }

      case USER_LOGOUT:
         return INITIAL_STATE

      case USER_UPDATE:
         return { ...state, user: { ...state.user, ...action.payload } }
         
      default:
         return { ...state }
   }
}

export default authReducer