import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Spinner, Row, Col, Card, CardHeader, CardBody, Button, Badge } from 'reactstrap'
import { Empty, Divider } from 'antd'
import styled from 'styled-components'

import { getModule } from '../Admin/Courses/services'
import PageTitle from '../../components/PageTitle'
import { handleError } from '../../helpers'

function ModuleProfile({ moduleId, history }) {
	const [module, setModule] = useState(null)

	useEffect(() => {
		!module && getModule(moduleId, { include: 'activeChapters,course' })
			.then(res => setModule(res.data.data))
			.catch(error => handleError(error))
	}, [module, moduleId])

	if(!module) return <Spinner />

	if(!module.course.is_allowed) history.push('/catalog')

	return (
		<React.Fragment>
			<PageTitle title={module.name} directory={module.course.name} />
			<Row>
				<Col md="4">
					<Card>
						<CardHeader className="text-muted">Módulo</CardHeader>
						<CardBody>
							<p className="text-muted small">
								<i className="fa fa-rocket mr-2" />Curso: {module.course.name}
							</p>
							<h5>{module.name}</h5>
							<p className="text-muted">{module.description}</p>
							<Divider />
							<Link to={`/courses/${module.course_id}`}>
								<Button color="primary" outline>
									<i className="fa fa-arrow-left mr-2"/>Ver todos los módulos
								</Button>
							</Link>
						</CardBody>
					</Card>
				</Col>
				<Col>
					{ module.active_chapters.length > 0 ? (
						<React.Fragment>
							{ module.active_chapters.map( (chapter,index) => (
								<Card key={chapter.id}>
									<CardHeader>
										<span className="text-muted">Capítulo {index+1}:</span> {chapter.name}
									</CardHeader>
									<CardBody>
										{ chapter.active_contents.length > 0 ? (
											<React.Fragment>
												{ chapter.active_contents.map( (content, index) => (
													<Link to={`/${content.content_type}/${content.id}`} key={content.id}>
														<ContentCard>
															<p className="mb-0 text-dark">
																<Badge color={content.is_completed ? 'success' : 'warning'} className="mr-2">
																	{content.is_completed ? 'Completada' : 'Pendiente'}
																</Badge>
																<small>
																	{content.content_type === 'lessons' && <i className="fa fa-video mr-2" /> }
																</small>
																{content.content.name}
															</p>
															<p className="text-muted small mb-0">{content.content.description}</p>
														</ContentCard>
													</Link>
												)) }
											</React.Fragment>
										) : <Empty description="Este capítulo no tiene contenidos" />}
									</CardBody>
								</Card>
							)) }
						</React.Fragment>
					) : <Empty description="No tiene capítulos este módulo" />}
				</Col>
			</Row>
		</React.Fragment>
	)
}

const ContentCard = styled.div`
	background-color: #f9f9fc;
	cursor: pointer;
	padding: 15px;
	margin-bottom: 10px;
	&:hover {
		background-color: #ebedf2;
	}
`

export default withRouter(ModuleProfile)