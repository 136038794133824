import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Layout from "../../layouts/SideLayout"

import AdminDashboard from './Dashboard'
import Courses from './Courses'
import Users from './Users'
import Lessons from './Lessons'
import Questions from './Questions'
import Attachments from './Attachments'
import Settings from './Settings'
import ProductsCatalog from './Catalogs/ProductsCatalog'
import ContentCatalog from './Catalogs/ContentCatalog'
import ActivitiesCatalog from './Catalogs/ActivitiesCatalog'
import Tasks from './Tasks'
import Answers from './Answers'
import Solutions from './Solutions'

export default function Admin() {
	return (
		<Switch>
			<Redirect exact path="/admin" to="/admin/dashboard" />

			<Layout navbar="admin">
				<Route path="/admin/dashboard" component={AdminDashboard} />
				<Route path="/admin/products" component={ProductsCatalog} />
				<Route path="/admin/courses" component={Courses} />
				<Route path="/admin/contents" component={ContentCatalog} />
				<Route path="/admin/lessons" component={Lessons} />
				<Route path="/admin/activities" component={ActivitiesCatalog} />
				<Route path="/admin/questions" component={Questions} />
				<Route path="/admin/attachments" component={Attachments} />
				<Route path="/admin/tasks" component={Tasks} />
				<Route path="/admin/answers" component={Answers} />
				<Route path="/admin/solutions" component={Solutions} />
				<Route path="/admin/users" component={Users} />
				<Route exact path="/admin/settings" component={Settings} />
			</Layout>

			<Redirect to="/404" />
		</Switch>
	)
}