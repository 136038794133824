import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Layout from "../../layouts/SideLayout"
import CourseProfile from './CourseProfile'
import ModuleProfile from './ModuleProfile'

export default function Course() {
	return (
		<Layout>
			<Switch>
				<Route exact path="/courses/:id" 
					render={props => <CourseProfile courseId={props.match.params.id} />}
				/>
				<Route exact path="/courses/modules/:id"
					render={props => <ModuleProfile moduleId={props.match.params.id} />}
				/>

				<Redirect to="404" />
			</Switch>
		</Layout>
	)
}