import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Popconfirm, message } from 'antd'

import { updateCourse, deleteCourse } from '../services'
import { handleError } from '../../../../helpers'

export default function EditCourse({ visible, onClose, reload, course }) {
	const { handleSubmit, register, errors } = useForm()
	const [loading, setLoading] = useState(false)

	if(!course) return null

	const onSubmit = values => {
		setLoading(true)
		updateCourse(course.id, values)
			.then(() => {
				message.success('Curso actualizado exitosamenente.')
				setLoading(false)
				reload()
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	const handleDelete = () => {
		deleteCourse(course.id)
			.then(() => {
				message.success('El curso ha sido eliminado')
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={()=>onClose()}>
			<ModalHeader toggle={()=>onClose()}>Editar Detalles del Curso</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre del Curso <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							defaultValue={course.name}
							ref={register({ required: true })}
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Descripción del Curso <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							as="textarea"
							name="description"
							defaultValue={course.description}
							ref={register({ required: true })}
						/>
						{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>URL Logo del Curso</Form.Label>
						<Form.Control 
							name="logo"
							defaultValue={course.logo}
							ref={register({})}
						/>
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<div className="float-left">
						<Popconfirm
							title="Se eliminar el curso, sus módulos y capítulos"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={handleDelete}
							placement="left"
							okButtonProps={{ danger: true }}
						>
							<Button color="danger" outline>
								<i className="fa fa-trash" /> Eliminar Curso
							</Button>
						</Popconfirm>
					</div>
					<Button color="primary" type="submit" disabled={loading}>
						Actualizar Curso {loading && <Spinner size="sm" className="ml-2" /> }
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}