import React from 'react'
import { Spinner } from 'reactstrap'
import { Table, Empty } from 'antd'

import EmptyImg from '../../../../assets/images/undraw/nodata.svg'

export default function SolutionsTable({ solutions }) {

	if(!solutions) return <Spinner />

	const columns = [
		{
			title: 'Alumno',
			dataIndex: 'user',
			render: text => text.name
		},
		{
			title: 'Tarea',
			dataIndex: 'task',
			render: text => text.name
		},
		{
			title: 'Clase',
			dataIndex: 'task',
			render: text => text.taskable.name
		},
	]

	return (
		<React.Fragment>						
			{ solutions.length > 0 ? (
				<Table 
					columns={columns}
					dataSource={solutions}
					rowKey={record => record.id}
					expandable={{
						expandedRowRender: record => <p style={{ margin: 0 }}>{record.solution}</p>,
					}}
				/>
			) : (
				<Empty 
					description="No se han recibido respuestas."
					image={EmptyImg}
				/>
			)}
		</React.Fragment>
	)
}