import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { createUser } from '../services'
import { handleError } from '../../../../helpers'

export default function NewUser({ visible, onClose, reload }) {
	const { handleSubmit, register, errors } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		values.email = values.email.toLowerCase()
		createUser(values)
			.then(() => {
				message.success('Usuario creado exitosamente.')
				setLoading(false)
				reload()
			})
			.catch(error => {
				setLoading(false)
				handleError(error)
			})
	}

	return (
		<Modal isOpen={visible} toggle={()=>onClose()}>
			<ModalHeader toggle={()=>onClose()}>Crear Usuario Nuevo</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre Completo <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							placeholder="Escribe el nombre completo"
							ref={register({ required: true })}
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Correo Electrónico <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="email"
							type="email"
							placeholder="Escribe el correo electrónico"
							ref={register({ required: true })}
						/>
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Contraseña <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								name="password"
								type="password"
								ref={register({ required: true })}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Confirmar Contraseña <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								name="password_confirmation"
								type="password"
								ref={register({ required: true })}
							/>
						</Form.Group>
					</Row>					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						Crear Usuario {loading && <Spinner size="sm" />}
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}