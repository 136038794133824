import s4lmsapi from "../../../services/s4lmsapi";

export const getCourses = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/courses`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getCourse = (id, params) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/courses/${id}`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createCourse = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/courses', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateCourse = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/courses/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteCourse = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/courses/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getModules = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/modules`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getModule = (id, params) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/modules/${id}`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createModule = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/modules', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateModule = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/modules/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteModule = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/modules/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getChapters = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/chapters`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getChapter = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/chapters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createChapter = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/chapters', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateChapter = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/chapters/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteChapter = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/chapters/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getContents = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/contents`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getContent = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/contents/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createContent = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/contents', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateContent = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/contents/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteContent = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/contents/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getCourseUsers = (courseId) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/courses/${courseId}/users`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const assignCourseUser = (courseId, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post(`/courses/${courseId}/users`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const detachCourseUser = (courseId, userId) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/courses/${courseId}/users/${userId}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)