import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, Spinner, ModalFooter, Button } from 'reactstrap'

import { assignCourseUser, detachCourseUser, getCourses } from '../../Courses/services'
import { handleError } from '../../../../helpers'
import { Empty, Switch, message } from 'antd'
import { getUser } from '../services'

export default function UserPermissions({ visible, onClose, user }) {
	const [courses, setCourses] = useState(null)
	const [currentUser, setCurrentUser] = useState(null)

	useEffect(()=>{
		!currentUser && user && getUser(user.id)
			.then(res => setCurrentUser(res.data.data))
			.catch(error => handleError(error))
	}, [currentUser, user])

	useEffect(()=>{
		!courses && currentUser && getCourses()
			.then(res => setCourses(res.data.data))
			.catch(error => handleError(error))
	}, [courses, currentUser])

	if(!user) return null

	const assignUser = (courseId) => {
		assignCourseUser(courseId, {
			user_id: currentUser.id,
			state: 'active'
		})
			.then(() => {
				message.success('Permiso concedido exitosamente.')
				setCurrentUser(null)
			})
			.catch(error => handleError(error))
	}

	const detachUser = (courseId) => {
		detachCourseUser(courseId, user.id)
			.then(()=>{
				message.success('Permiso eliminado exitosamente.')
				setCurrentUser(null)
			})
			.catch(error => handleError(error))
	}

	const handleClose = () => {
		setCurrentUser(null)
		setCourses(null)
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Accesos de {currentUser && currentUser.name}</ModalHeader>
				<ModalBody className="my-3">
					{ courses && currentUser ? (
						<React.Fragment>
							{ courses.length > 0 ? (
								<React.Fragment>
									{ courses.map(course => (
										<div key={course.id} className="mb-2">
											<Switch 
												className="mr-2"
												defaultChecked={currentUser.courses_ids_allowed.includes(course.id)}
												onChange={checked => checked ? assignUser(course.id) : detachUser(course.id)} 
											/>
											<span>{course.name}</span>
										</div>
									)) }
								</React.Fragment>	
							) : <Empty description="No existen cursos" />}
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handleClose} outline>Finalizar</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}