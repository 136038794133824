import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'

import { getUsers } from '../../Users/services'
import { handleError } from '../../../../helpers'
import { getTasks } from '../../Tasks/services'
import { Button } from 'reactstrap'

const { Option } = Select;

export default function TableFilters({ filterUserId, setFilterUserId, filterTaskId, setFilterTaskId, handleSearch }) {
	const [users, setUsers] = useState(null)
	const [tasks, setTasks] = useState(null)

	useEffect(()=>{
		!users && getUsers()
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users])

	useEffect(()=>{
		!tasks && getTasks()
			.then(res => setTasks(res.data.data))
			.catch(error => handleError(error))
	}, [tasks])

	return (
		<React.Fragment>
			<p className="mb-2">Filtrar por Usuario</p>
			<Spin spinning={users ? false : true}>
				<Select
					showSearch
					style={{ width: '100%' }}
					placeholder="Eliga un usuario"
					optionFilterProp="children"
					onChange={id => setFilterUserId(id)}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					value={filterUserId}
				>
					<Option value="">:: Ver todos ::</Option>
					{ users && users.map(user => 
						<Option key={user.id} value={user.id}>{user.name}</Option>
					)}
				</Select>
				<p className="mb-2 mt-3">Filtrar por Reto</p>
				<Spin spinning={tasks ? false : true}>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder="Eliga un reto"
						optionFilterProp="children"
						onChange={id => setFilterTaskId(id)}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={filterTaskId}
					>
						<Option value="">:: Ver todas ::</Option>
						{ tasks && tasks.map(task => 
							<Option key={task.id} value={task.id}>{task.name}</Option>
						)}
					</Select>
				</Spin>
				<Button color="primary" onClick={handleSearch} className="mt-3">
					Filtrar Resultados
				</Button>
			</Spin>
		</React.Fragment>
	)
}