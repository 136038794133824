import React from 'react';
import { useSelector } from 'react-redux'

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import profileImg from "../../../assets/images/profile-img.png";

const WelcomeWidget = (props) => {
	const auth = useSelector(state => state.auth)

	return (
		<React.Fragment>
			<Card className="overflow-hidden">
				<div className="bg-soft-primary">
					<Row>
							<Col xs="7">
								<div className="txt-primary p-3">
									<h3 className="txt-primary mb-1">Hola!</h3>
									<p className="mb-0">{auth.user.name}</p>
								</div>
							</Col>
							<Col xs="5" className="align-self-end">
								<img src={profileImg} alt="" className="img-fluid" />
							</Col>
					</Row>
				</div>
				<CardBody className="pt-0">
					<Row>
						<Col sm="8">
							<div className="pt-2">
								<div className="mt-2">
									<Link to="/profile" className="btn btn-primary waves-effect waves-light btn-sm">
										Ver Mi Perfil <i className="mdi mdi-arrow-right ml-1"></i>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}
export default WelcomeWidget;
 