import React, { useState, useEffect } from 'react'
import { Spinner, Badge } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import { getQuestions } from '../Admin/Questions/services'
import { handleError } from '../../helpers'
import AnswerModal from './AnswerModal'

export default function QuestionsTab({ content }) {
	const [questions, setQuestions] = useState(null)
	const [answerQuestion, setAnswerQuestion] = useState(null)

	useEffect(()=>{
		!questions && getQuestions({ 
			"filter[questionable_type]": content.content_type,
			"filter[questionable_id]": content.content_id,
			"filter[active]": 1
		})
			.then(res => setQuestions(res.data.data))
			.catch(error => handleError(error))
	}, [content, questions])

	if(!questions) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ questions.length > 0 ? (
				<React.Fragment>
					{ questions.map( (question, index) => (
						<QuestionDiv key={question.id} onClick={()=>setAnswerQuestion(question)}>
							<Badge size="sm" color={question.answer ? 'success' : 'warning'} className="mb-2">
								{question.answer ? 'Respondida' : 'Pendiente'}
							</Badge>
							<h6>{question.name}</h6>
							<p className="text-muted small mb-2">{question.description}</p>							
						</QuestionDiv>
					)) }
				</React.Fragment>
			) : <Empty description="No hay preguntas para este contenido" />}
			<AnswerModal 
				visible={answerQuestion ? true : false}
				onClose={()=>setAnswerQuestion(null)}
				reload={()=> { setAnswerQuestion(null); setQuestions(null) }}
				question={answerQuestion}
			/>
		</React.Fragment>
	)
}

const QuestionDiv = styled.div`
	background-color: #ebedf2;
	padding: 15px;
	margin-bottom: 10px;
	cursor: pointer;
	&:hover {
		background-color: #f9f9fc;
	}
`