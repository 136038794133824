import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardHeader, CardBody, Spinner } from 'reactstrap'

import Layout from "../../layouts/SideLayout"
import PageTitle from '../../components/PageTitle'
import StatCard from '../../components/StatCard'
import PasswordUpdate from './PasswordUpdate'
import AccountDetails from './AccountDetails'
import CustomDetails from './CustomDetails'
import { getUser } from '../Admin/Users/services'
import { handleError } from '../../helpers'

export default function Profile() {
	const auth = useSelector(state => state.auth)
	const [user, setUser] = useState(null)

	useEffect(() => {
		!user && getUser(auth.user.id, { include: 'notesCount,commentsCount,answersCount,completedLogsCount' })
			.then(res => setUser(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<Layout>
			<PageTitle title="Mi Perfil" directory="Cuenta" />
			<Row>
				<Col md="4">
					<Card>
						<CardHeader>Datos Personales</CardHeader>
						<CardBody>
							<AccountDetails user={user} reload={()=>setUser(null)} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>Actualizar Contraseña</CardHeader>
						<CardBody>
							<PasswordUpdate userId={auth.user.id} />
						</CardBody>
					</Card>
				</Col>
				<Col md="5">
					<Card>
						<CardHeader>Datos Visibles a la Comunidad</CardHeader>
						<CardBody>
							<CustomDetails userId={auth.user.id} />
						</CardBody>
					</Card>
				</Col>
				<Col md="3">
					{ user ? (
						<React.Fragment>
							<StatCard title="Clases Completadas" count={user.completed_logs_count} iconClass="bx-video-recording" />
							<StatCard title="Notas Creadas" count={user.notes_count} iconClass="bx-note" />
							<StatCard title="Preguntas Resueltas" count={user.answers_count} iconClass="bx-question-mark" />
							<StatCard title="Comentarios" count={user.comments_count} iconClass="bx-chat" />
						</React.Fragment>
					) : <Spinner size="sm" />}
				</Col>
			</Row>
		</Layout>
	)
}