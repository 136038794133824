export const USER_LOGIN = "auth/USER_LOGIN"
export const USER_LOGOUT = "auth/USER_LOGOUT"
export const USER_UPDATE = "auth/USER_UPDATE"

export const userLogin = data => ({
   type: USER_LOGIN,
   payload: data
})

export const userLogout = () => ({
   type: USER_LOGOUT,
   payload: null
})

export const userUpdate = data => ({
   type: USER_UPDATE,
   payload: data
})