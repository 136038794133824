import React from 'react';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SimpleBar from "simplebar-react";

import AdminNavbar from "./AdminNavbar";
import UserNavbar from './UserNavbar';

const Sidebar = (props) => {
	return (
		<React.Fragment>
			<div className="vertical-menu">
				<div data-simplebar className="h-100">
					{props.type !== "condensed" ? (
						<SimpleBar style={{ maxHeight: "100%" }}>
							{ props.navbar === 'admin' ? 
								<AdminNavbar />
							: 
								<UserNavbar />
							}
						</SimpleBar>
					) : (
						<React.Fragment>
							{ props.navbar === 'admin' ? 
								<AdminNavbar />
							: 
								<UserNavbar />
							}
						</React.Fragment>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

const mapStatetoProps = state => {
	return {
		layout: state.Layout
	};
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
