import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment'

import { userUpdate } from '../Authentication/actions'
import { updateUser } from '../Admin/Users/services'
import { handleError } from '../../helpers'
import { storeFile } from '../Admin/Files/services'

export default function AccountDetails({ user, reload }) {
	const { handleSubmit, register, errors } = useForm()
	const [updateModal, setUpdateModal] = useState(false)
	const [imageLoading, setImageLoading] = useState(false)
	const [imageUrl, setImageUrl] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		user && !imageUrl && !imageLoading && setImageUrl(user.profile_url ? `${user.profile_url}?nocache=${Math.random().toString()}` : '')
	}, [user, imageUrl, imageLoading])

	const onSubmit = values => {		
		if(values.email === user.email) delete values.email
		updateUser(user.id, values)
			.then(() => {
				message.success('Datos actualizados exitosamente')
				dispatch(userUpdate(values))
				setUpdateModal(false)
			})
			.catch(error => handleError(error))
	}

	const beforeUpload = file => {
		if (!file.type === 'image/jpeg' || !file.type === 'image/png'){
			message.warning('Solo puede cargar archivos jpg o png');
			return false;
		}

		if ((file.size / 1024 / 1024) > 0.4){
		   message.warning('La imagén no puede superar los 400 KB');
			return false;
		}

		return true;
	}

	const handleUpload = data => {
		const formData = new FormData();
		formData.append('file', data.file);
		formData.append('directory', 'profiles');
		formData.append('key', user.id);

		setImageLoading(true)
		setImageUrl(null)

		storeFile(formData)
			.then(res => {
				setImageLoading(false)
				reload()
			})
			.catch(error => handleError(error))
	}
  
	const uploadButton = (
      <React.Fragment>
        {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Foto de Perfil</div>
      </React.Fragment>
	);

	if(!user) return <Spinner />

	return (
		<React.Fragment>
			<Upload
				name="file"
				listType="picture-card"
				showUploadList={false}
				beforeUpload={beforeUpload}
				customRequest={handleUpload}
			>
				{imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
			</Upload>
			<DetailText><span className="text-muted">Nombre Completo:</span> {user.name}</DetailText>
			<DetailText><span className="text-muted">Correo Electrónico:</span> {user.email}</DetailText>
			<DetailText><span className="text-muted">Fecha de Registro:</span> {moment(user.created_at).format('L')}</DetailText>
			<Button 
				color="primary" 
				outline 
				size="sm" 
				className="mt-2"
				onClick={()=>setUpdateModal(true)}
			>
				Actualizar Datos
			</Button>

			<Modal isOpen={updateModal} toggle={()=>setUpdateModal(false)}>
				<ModalHeader toggle={()=>setUpdateModal(false)}>Actualizar Datos</ModalHeader>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody>						
						<Form.Group>
							<Form.Label>Nombre Completo <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								name="name"
								defaultValue={user.name}
								ref={register({ required: true })}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Correo Electrónico <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								name="email"
								type="email"
								defaultValue={user.email}
								ref={register({ required: true })}
							/>
							{ errors.email && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
						</Form.Group>
					</ModalBody>
					<ModalFooter>
						<Button color="primary">Actualizar Perfil</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</React.Fragment>
	)
}

const DetailText = styled.p`
	margin-bottom: 7px;
	font-size: 13px;
`