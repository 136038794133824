import React, {useState, useEffect} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import AnswersTable from './partials/AnswersTable'
import { getAnswers } from './services'
import { handleError } from '../../../helpers'
import TableFilters from './partials/TableFilters'

export default function AnswersList() {
	const [answers, setAnswers] = useState(null)	
	const [filterUserId, setFilterUserId] = useState('')
	const [filterQuestionId, setFilterQuestionId] = useState('')

	useEffect(() => {
		!answers && getAnswers({ 
			include: 'user,question,question.content',
			"filter[user_id]": filterUserId,
			"filter[question_id]": filterQuestionId
		})
			.then(res => {
				setAnswers(res.data.data)
			})
			.catch(error => handleError(error))
	}, [answers, filterQuestionId, filterUserId])

	return (
		<React.Fragment>
			<PageTitle title="Respuestas de Preguntas" directory="Actividades" />
			<Row>
				<Col md="3" sm="6">					
					<Card>
						<CardBody>
							<CardTitle>Filtrar Resultados</CardTitle>
							<Divider className="mt-0 mb-3" />
							<TableFilters
								filterUserId={filterUserId}
								setFilterUserId={setFilterUserId}
								filterQuestionId={filterQuestionId}
								setFilterQuestionId={setFilterQuestionId}
								handleSearch={()=>setAnswers(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Listado de Respuestas</CardTitle>
							<Divider className="mt-0 mb-3" />
							<AnswersTable 
								answers={answers} 
								reload={() => setAnswers(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}