import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import ReactPlayer from 'react-player'

export default function VideoWidget() {
	const settings = useSelector(state => state.settings)

	return (
		<React.Fragment>
			{ settings.welcome_video && (
				<Card>
					<CardHeader>Un mensaje para ti</CardHeader>
					<CardBody>
						<ReactPlayer 
							url={settings.welcome_video}
							width="100%"
							height="230px"
							controls={true}
							style={{ backgroundColor: '#111' }}
						/>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	)
}