import s4lmsapi from "../../../services/s4lmsapi";

export const getQuestions = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/questions`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getQuestion = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/questions/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createQuestion = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/questions', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateQuestion = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/questions/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteQuestion = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/questions/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)