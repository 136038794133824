import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {Form, Button} from 'react-bootstrap'
import {message} from 'antd'

import AuthLayout from "../../layouts/AuthLayout"
import {handleError} from '../../helpers'
import {forgot} from './services'

export default function Forgot() {	
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, errors, reset } = useForm()	
	
	const onSubmit = values => {
		setLoading(true)
		forgot(values)
			.then(() => {
				message.success('Se envío un correo con las instrucciones a seguir.')				
				reset()
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<AuthLayout title="Recuperar Contraseña" description="Solicite su nueva contraseña">
			<Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Control 
						name="email" 
						placeholder="Escriba su correo electrónico" 
						type="email"
						ref={register({ required: true })}
					/>
					{errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text>}
				</Form.Group>

				<div className="mt-3">
					<Button 
						className="btn-block waves-effect waves-light" 
						type="submit"
						disabled={loading}
					>
						{!loading ? 'Recuperar Contraseña' : 'Solicitando contraseña...' }
					</Button>
				</div>

				<div className="mt-4 text-center">
					<Link to="/auth/login" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Iniciar Sesión</Link>
				</div>
			</Form>
		</AuthLayout>
	)
}