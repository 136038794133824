import React, {useState} from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import styled from 'styled-components'

import PageTitle from '../../../components/PageTitle'
import UserCustomFields from './UserCustomFields'
import AccountSettings from './AccountSettings'
import { Divider } from 'antd'

export default function Settings() {
	const [currentView, setCurrentView] = useState('account')

	return (
		<React.Fragment>
			<PageTitle title="Ajustes" directory="Administrador" />
			<Row>
				<Col md="3" sm="6">
					<Card>
						<CardBody>
							<h6>Menú de Ajustes</h6>
							<Divider className="mt-2 mb-3" />
							<MenuItem 
								active={currentView === 'userCustomFields' ? true : false}
								onClick={()=>setCurrentView('userCustomFields')}
							>
								Campos Personalizados
							</MenuItem>
							<MenuItem 
								active={currentView === 'account' ? true : false}
								onClick={()=>setCurrentView('account')}
							>
								Personalizar App
							</MenuItem>
						</CardBody>
					</Card>
				</Col>
				<Col>
					{ currentView === 'userCustomFields' && <UserCustomFields /> }
					{ currentView === 'account' && <AccountSettings /> }
				</Col>
			</Row>
		</React.Fragment>
	)
}

const MenuItem = styled.div`
	background-color: ${props => props.active ? '#ebedf2;' : '#f9f9fc;' }
	border: 1px solid #ebedf2;
	padding: 10px;
	text-align: center;
	text-decoration: ${props => props.active ? 'underline;' : 'none;' }
	cursor: pointer;
	margin-bottom: 5px;
	&:hover {
		background-color: #ebedf2;
	}
`