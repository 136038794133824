import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import {Container, Description} from './ProductsCatalog'
import PageTitle from '../../../components/PageTitle'
import VideoImg from '../../../assets/images/undraw/video.svg'

export default function ContentCatalog() {

	const contents = [
		{
			image: VideoImg,
			title: 'Clase en Video',
			description: 'Otorga el contenido de valor por medio de video.',
			btnText: 'Ver clases de video',
			btnLink: '/admin/lessons'
		}
	]

	return (
		<React.Fragment>
			<PageTitle title="Contenido" directory="Administrador" />
			<Row>
				{ contents.map((content,i) => (
					<Col md="4" lg="3" key={i}>
						<Container>
							<img src={content.image} alt={content.title} width="70%" />
							<h4 className="mt-2">{content.title}</h4>
							<Description>{content.description}</Description>
							<Link to={content.btnLink}>
								<Button color="primary" size="sm">{content.btnText}</Button>
							</Link>
						</Container>
					</Col>
				)) }
			</Row>
		</React.Fragment>
	)
}