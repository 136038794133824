import React from 'react'
import { Row, Col } from 'reactstrap'

import StatCard from '../../../../components/StatCard'

export default function StatsRow({ stats }) {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<StatCard 
						title="Total de Miembros" 
						count={stats.members_count}
						iconClass="bx-user"
					/>
				</Col>
				<Col>
					<StatCard 
						title="Clases Completadas" 
						count={stats.content_completed_count}
						iconClass="bx-video-recording"
					/>
				</Col>
				<Col>
					<StatCard 
						title="Total de Inforproductos" 
						count={stats.products_count}
						iconClass="bx-book"
					/>
				</Col>
				<Col>
					<StatCard 
						title="Total de Comentarios" 
						count={stats.comments_count}
						iconClass="bx-chat"
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}