import React from 'react';
import {useSelector} from "react-redux"
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
    const settings = useSelector(state => state.settings)

    return (
        <footer className="footer">
            <Container fluid={true}>
                <Row>
                    <Col md={6}></Col>
                    <Col md={6}>
                        <div className="text-sm-right d-none d-sm-block">
					        Versión {process.env.REACT_APP_VERSION}
                            {" | "}
                            {new Date().getFullYear()} © {settings.app_name} - Desarrollado por Smart4 Solutions SAS
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;