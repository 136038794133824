import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import CoursesList from './CoursesList'
import CourseProfile from './CourseProfile'

export default function Courses() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/courses" component={CoursesList} />
				<Route path="/admin/courses/:id" component={CourseProfile} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}