import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { Spinner, Row, Col, Card, CardBody, Button, CardHeader, Badge } from 'reactstrap'
import { Tabs, Popconfirm, message } from 'antd';
import moment from 'moment'

import Layout from "../../layouts/SideLayout"
import PageTitle from '../../components/PageTitle'
import NotesTab from './NotesTab';
import CommentsTab from './CommentsTab';
import QuestionsTab from './QuestionsTab';
import TasksTab from './TasksTab';
import AttachmentsTab from './AttachmentsTab';
import ProductContents from './ProductContents';

import { getContent } from '../Admin/Courses/services';
import { handleError } from '../../helpers'
import { createLog } from '../Admin/Logs/services';

const { TabPane } = Tabs;

export default function Lesson(props) {
	const contentId = props.match.params.id
	const [content, setContent] = useState(null)
	const [currentContentId, setCurrentContentId] = useState(contentId)
	const auth = useSelector(state => state.auth)

	useEffect(()=>{
		!content && getContent(contentId)
			.then(res => setContent(res.data.data))
			.catch(error => handleError(error))
	}, [content, contentId])

	if(currentContentId !== contentId) {
		setCurrentContentId(contentId)
		setContent(null)
	}

	if(!content) return (
		<Layout>
			<Spinner />
		</Layout>
	)

	const lesson = content.content

	const getVideoUrl = () => {
		switch (lesson.video_source) {
			case 'vimeo':
				return `https://vimeo.com/${lesson.video_ref}`
			default:
				return `https://www.youtube.com/watch?v=${lesson.video_ref}`
		}
	}

	const handleCompleted = () => {
		createLog({
			'content_id': content.id,
			'user_id': auth.user.id,
			'type': 'content_completed',
			'payload': JSON.stringify({ completedAt: moment() })
		})
			.then(()=>{
				message.success('Clase completada exitosamente')
				setContent(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<Layout>
			<PageTitle title={(
				<React.Fragment>					
					<Badge color={content.is_completed ? 'success' : 'warning'} className="mr-2">
						{content.is_completed ? 'Completada' : 'Pendiente'}
					</Badge>
					{lesson.name}
				</React.Fragment>
			)}>
				{ !content.is_completed ? (
					<Popconfirm
						title="Marcar como finalizada"
						okText="Finalizar"
						cancelText="Cancelar"
						placement="left"
						onConfirm={handleCompleted}
					>
						<Button color="primary">
							Marcar como Completada
						</Button>
					</Popconfirm>
				) : (
					<React.Fragment>
						{ content.next_id && (
							<Link to={`/${content.next.content_type}/${content.next_id}`}>
								<Button color="primary">Siguiente Clase</Button>
							</Link>
						)}
					</React.Fragment>
				)}
			</PageTitle>
			<Row>
				<Col md="8">
					<Card className="lesson-video-container">
						<CardBody className="p-2">
							<ReactPlayer 
								url={getVideoUrl()}
								width="100%"
								height="380px"
								controls={true}
								style={{ backgroundColor: '#222' }}
							/>
							<div className="clearfix mt-3">
								<div className="float-left">																		
									<Button color="primary" outline onClick={()=>window.history.back()}>
										Volver Atrás
									</Button>
								</div>
								<div className="float-right">
									{ !content.is_completed ? (
										<Popconfirm
											title="Marcar como finalizada"
											okText="Finalizar"
											cancelText="Cancelar"
											onConfirm={handleCompleted}
										>
											<Button color="primary">
												Marcar como Completada
											</Button>
										</Popconfirm>
									) : (
										<React.Fragment>
											{ content.next_id && (
												<Link to={`/${content.next.content_type}/${content.next_id}`}>
													<Button color="primary">Siguiente Clase</Button>
												</Link>
											)}
										</React.Fragment>
									)}									
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody className="pt-1">
							<Tabs defaultActiveKey="notes">
								<TabPane tab="Notas" key="notes">
									<NotesTab contentId={content.id} />
								</TabPane>
								<TabPane tab="Comentarios" key="comments">
									<CommentsTab contentId={content.id} />
								</TabPane>
							</Tabs>
						</CardBody>
					</Card>
					<Card>
						<CardBody className="pt-1">
							<Tabs defaultActiveKey="attachments">
								<TabPane tab="Preguntas" key="questions">
									<QuestionsTab content={content} />
								</TabPane>
								<TabPane tab="Descargables" key="attachments">
									<AttachmentsTab content={content} />
								</TabPane>
								<TabPane tab="Retos" key="tasks">
									<TasksTab content={content} />
								</TabPane>
							</Tabs>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>Índice: {content.product.name}</CardHeader>
						<CardBody>
							<ProductContents 
								productId={content.product_id} 
								productType={content.product_type} 
								currentContentId={content.id}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Layout>
	)
}