import s4lmsapi from "../../../services/s4lmsapi";

export const getTasks = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/tasks`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getTask = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/tasks/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createTask = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/tasks', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateTask = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/tasks/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteTask = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/tasks/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)