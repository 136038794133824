import React from 'react';
import {useSelector} from "react-redux"

import { Row, Col, Card, CardBody, Container } from "reactstrap";

export default function AuthLayout(props) {
    const settings = useSelector(state => state.settings)

    return (
        <div className="account-pages my-5 pt-sm-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden">
                            <div className="bg-soft-primary">
                                <Row>
                                    <Col className="col-7">
                                        <div className="txt-primary p-4">
                                            <h4 className="txt-primary mb-0">{props.title}</h4>
                                            <p>{props.description}</p>
                                        </div>
                                    </Col>
                                    <Col className="col-5 align-self-center">
                                        <img src={settings.app_logo} width="150px" alt="Logo" className="img-fluid" />
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-4">
                                <div className="p-2">
                                    {props.children}
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-5 text-center">
                            {/* <p>¿Problemas para ingresar? <a href="mailto:soporte@smart4.com.co" className="font-weight-medium txt-primary"> Contacta al equipo de soporte </a></p> */}
                            <p>© {new Date().getFullYear()} Smart4 Solutions. Hecho con <i className="mdi mdi-heart-pulse text-danger"></i> <i className="mdi mdi-brain text-danger"></i><i className="mdi mdi-power-plug text-danger"></i> en Colombia</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}