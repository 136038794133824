import s4lmsapi from "../../../services/s4lmsapi";

export const getLogs = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/logs`, {params})
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createLog = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/logs', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)