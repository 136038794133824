import React from 'react'
import { Spinner } from 'reactstrap'
import { Table, Empty } from 'antd'

import EmptyImg from '../../../../assets/images/undraw/nodata.svg'

export default function AnswersTable({ answers }) {

	if(!answers) return <Spinner />

	const columns = [
		{
			title: 'Alumno',
			dataIndex: 'user',
			render: text => text.name
		},
		{
			title: 'Pregunta',
			dataIndex: 'question',
			render: text => text.name
		},
		{
			title: 'Clase',
			dataIndex: 'question',
			render: text => text.questionable.name
		},
	]

	return (
		<React.Fragment>						
			{ answers.length > 0 ? (
				<Table 
					columns={columns}
					dataSource={answers}
					rowKey={record => record.id}
					expandable={{
						expandedRowRender: record => <p style={{ margin: 0 }}>{record.answer}</p>,
					}}
				/>
			) : (
				<Empty 
					description="No se han recibido respuestas."
					image={EmptyImg}
				/>
			)}
		</React.Fragment>
	)
}