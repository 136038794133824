import React from 'react'
import { Link } from 'react-router-dom'
import Highlighter from "react-highlight-words";
import styled from 'styled-components'

export default function SearchBox({ link, term, name, context, description }) {
	return (
		<React.Fragment>
			
			<Link to={link}>
				<ResultContainer>
					<div className="float-right">
						<i className="fa fa-arrow-right" />
					</div>
					<small>{context}</small>
					<h6 className="mb-1">
						<i className="fa fa-video mr-2" />
						<Highlighter
							highlightClassName="highlight"
							searchWords={[term]}
							autoEscape={true}
							textToHighlight={name}
						/>
					</h6>					
					<p className="small text-muted mb-0">													
						<Highlighter
							highlightClassName="highlight"
							searchWords={[term]}
							autoEscape={true}
							textToHighlight={description}
						/>
					</p>
				</ResultContainer>
			</Link>
		</React.Fragment>
	)
}

const ResultContainer = styled.div`
	background-color: #f9f9fc;
	padding: 15px 15px;
	margin-bottom: 5px;
`