import React from 'react'
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default function NewFeatures({ tip, features, version }) {
	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<i className="fa fa-lightbulb mr-2 txt-primary" />
					Algo en que pensar
				</CardHeader>
				<CardBody>
					<TipContainer className="bg-soft-primary">
						<em>{tip}</em>
					</TipContainer>
				</CardBody>
			</Card>
			<Card>
				<CardHeader>
					<i className="fa fa-star mr-2 txt-primary" />
					Nuevas Funcionalidades
				</CardHeader>
				<CardBody className="bg-light p-2">
					<FeaturesContainer>
						{ features.map(feature => (
							<FeatureCard>
								<h6 className="mb-1">{feature.title}</h6>
								<p className={`small text-muted mb-${feature.link ? '2' : '0'}`}>{feature.desc}</p>
								{ feature.link && (
									<Link to={feature.link}>
										Ir a página
									</Link>
								)}
							</FeatureCard>
						)) }
					</FeaturesContainer>
					<div className="text-center my-2 small">
						<em>Desliza hacia abajo para ver mas</em>
					</div>
				</CardBody>
				{ version && (
					<CardFooter className="bg-white text-right">
						<small>versión actual {version}</small>
					</CardFooter>
				)}
			</Card>
		</React.Fragment>
	)
}

const TipContainer = styled.div`
	padding: 15px;
	text-align: center;
	font-size: 17px;
	line-height: 21px;
	color: #333;
	opacity: 0.8;
`

const FeaturesContainer = styled.div`
	max-height: 300px;
	overflow-y: scroll;
`

const FeatureCard = styled.div`
	background-color: #fff;
	margin-bottom: 10px;
	padding: 13px;
`