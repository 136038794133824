import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import Layout from "../../layouts/SideLayout"
import PageTitle from '../../components/PageTitle'
import UserNotes from './UserNotes'
import UserAnswers from './UserAnswers'
import UserSolutions from './UserSolutions'

export default function Notebook() {
	const auth = useSelector(state => state.auth)

	return (
		<Layout>
			<PageTitle title="Cuaderno Digital" directory="LMS" />
			<Row>
				<Col sm="6">
					<Card>
						<CardHeader>Notas de Clase</CardHeader>
						<CardBody>
							<UserNotes user={auth.user} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>Respuestas a Retos</CardHeader>
						<CardBody>
							<UserSolutions user={auth.user} />
						</CardBody>
					</Card>
				</Col>
				<Col sm="6">
					<Card>
						<CardHeader>Respuestas a Preguntas</CardHeader>
						<CardBody>
							<UserAnswers user={auth.user} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Layout>
	)
}