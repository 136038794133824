import React from 'react'
import { Row, Col } from 'reactstrap'

import Layout from "../../layouts/SideLayout"
import WelcomeWidget from './partials/WelcomeWidget'
import QuickLinksWidget from './partials/QuickLinksWidget'
import VideoWidget from './partials/VideoWidget'
import LastContentWidget from './partials/LastContentWidget'

export default function Dashboard() {
	return (
		<React.Fragment>
			<Layout>
				<Row>
					<Col md="4">
						<WelcomeWidget />
						<VideoWidget />
					</Col>
					<Col>
						<LastContentWidget />
						<QuickLinksWidget />
					</Col>
				</Row>
			</Layout>
		</React.Fragment>
	)
}