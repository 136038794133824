import React from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

import VideoImg from '../../../assets/images/undraw/video.svg'
import ResearchingImg from '../../../assets/images/undraw/researching.svg'
import GroupImg from '../../../assets/images/undraw/group.svg'

export default function QuickLinksWidget() {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Card>
						<CardBody className="text-center">
							<img src={VideoImg} alt="Contenidos" width="90%" className="my-4" />
							<Link to="/catalog" className="btn btn-outline-primary waves-effect waves-light btn-sm">
								Ver Contenidos <i className="mdi mdi-arrow-right ml-1"></i>
							</Link>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody className="text-center">
							<img src={ResearchingImg} alt="Cuaderno Digital" width="90%" className="mb-4" />
							<Link to="/notebook" className="btn btn-outline-primary waves-effect waves-light btn-sm">
								Cuaderno Digital <i className="mdi mdi-arrow-right ml-1"></i>
							</Link>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody className="text-center">
							<img src={GroupImg} alt="Comunidad" width="90%" className="my-3" />
							<Link to="/community" className="btn btn-outline-primary waves-effect waves-light btn-sm">
								Comunidad <i className="mdi mdi-arrow-right ml-1"></i>
							</Link>
						</CardBody>
					</Card>
				</Col>				
			</Row>
		</React.Fragment>
	)
}