import s4lmsapi from "../../../services/s4lmsapi";

export const getCustomFields = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/custom_fields`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getCustomField = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/custom_fields/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createCustomField = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/custom_fields', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateCustomField = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/custom_fields/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteCustomField = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/custom_fields/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getSettings = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/public/settings`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createSetting = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/settings', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getPublicSettings = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/public/settings`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)