import React, {useState, useEffect} from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Divider } from 'antd'

import PageTitle from '../../../components/PageTitle'
import ContentSearchBar, { NoContentSelected } from '../../../components/ContentSearchBar'
import NewTask from './partials/NewTask'
import TasksTable from './partials/TasksTable'
import { getTasks } from './services'
import { handleError } from '../../../helpers'

export default function TasksList() {
	const [type, setType] = useState('lessons')
	const [contentId, setContentId] = useState(null)
	const [tasks, setTasks] = useState(null)
	const [currentContentId, setCurrentContentId] = useState(null)

	useEffect(() => {
		contentId && !tasks && getTasks({ 
			"filter[taskable_type]": type,
			"filter[taskable_id]": contentId
		})
			.then(res => {
				setTasks(res.data.data)
				setCurrentContentId(contentId)
			})
			.catch(error => handleError(error))
	}, [contentId, tasks, type])

	useEffect(() => {
		if(currentContentId !== contentId) 
			setTasks(null)
	}, [contentId, currentContentId])

	return (
		<React.Fragment>
			<PageTitle title="Retos de Clase" directory="Actividades" />
			<ContentSearchBar type={type} setType={setType} setContentId={setContentId} />
			<Row>
				<Col md="4" sm="6">					
					<Card>
						<CardBody>
							<CardTitle>Agregar Reto Nuevo</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<NewTask 
									type={type}
									contentId={contentId}
									reload={()=>setTasks(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardBody>
							<CardTitle>Listado de Retos Creados</CardTitle>
							<Divider className="mt-0 mb-3" />
							{ contentId ? (
								<TasksTable 
									tasks={tasks} 
									reload={() => setTasks(null)}
								/>
							) : <NoContentSelected />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}