import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import SearchEntity from '../../../../components/SearchEntity'
import { updateContent } from '../services'
import { handleError } from '../../../../helpers'

export default function EditContent({ visible, onClose, reload, content }) {
	const { handleSubmit, register, errors } = useForm()
	const [nextId, setNextId] = useState(null)

	const onSubmit = values => {
		updateContent(content.id, values)
			.then(() => {
				message.success('Contenido actualizado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	if(!content) return null

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Contenido</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}> 
				<ModalBody>					
					<Form.Group>
						<Form.Label>Próximo Contenido</Form.Label>
						<div className="bg-light p-2 mb-2">
							<p className="text-muted mb-0">
								Actual: {content.next ? content.next.name : <span className="small"><em>Sin próximo contenido</em></span>}
							</p>
						</div>
						<SearchEntity 
							style={{ width: '100%' }}
							resourceType="contents"
							setResourceId={setNextId}
						/>
						<Form.Text className="text-muted">Busque el contenido por nombre</Form.Text>
						<input type="hidden" value={nextId ? nextId : ''} name="next_id" ref={register({})} />
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								type="integer"
								name="order"
								defaultValue={content.order}
									ref={register({ required: true })}
								/>
								{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="active"			
								defaultValue={content.active ? 1 : 0}
								ref={register({})}
							>
								<option value={1}>Publicada</option>
								<option value={0}>Borrador</option>
							</Form.Control>
						</Form.Group>
					</Row>					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit">Actualizar Contenido</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}