import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Select } from 'antd'

import { createCustomField } from '../services'
import { handleError } from '../../../../helpers'

export default function NewField({ visible, onClose, reload }) {
	const { handleSubmit, register, errors, watch, setValue } = useForm()
	
	const onSubmit = values => {
		values.model = 'users'
		createCustomField(values)
			.then(()=>{
				message.success('Campo creado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	let type = watch('type')

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Campo Personalizado para Usuarios</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre del Campo <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							ref={register({ required: true })}							
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Texto de Ayuda <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="description"
							ref={register({ required: true })}							
						/>
						{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Requerido <span className="txt-primary">*</span></Form.Label>
							<Form.Control
								as="select"
								name="required"
								ref={register({ required: true })}
							>
								<option value={0}>No es requerido</option>
								<option value={1}>Si es requerido</option>
							</Form.Control>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Tipo de Campo <span className="txt-primary">*</span></Form.Label>
							<Form.Control
								as="select"
								name="type"
								ref={register({ required: true })}
							>
								<option value="">:: Elige una opción ::</option>
								<option value="string">Texto</option>
								<option value="integer">Númerico</option>
								<option value="date">Fecha</option>
								<option value="json">Selección Múltiple</option>
							</Form.Control>
						</Form.Group>
					</Row>
					{ type === 'json' && (
                  <Form.Group>
                     <Form.Label>Opciones <span className="txt-primary">*</span></Form.Label>
                     <input type="hidden" name="options" ref={register({ required: true })} />
							<Select 
								mode="tags" 
								style={{ width: '100%' }} 
								placeholder="Escriba las opciones" 
								onChange={values => setValue('options', JSON.stringify(values))} 
							/>
                     { errors.options && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
                  </Form.Group>
               )}
				</ModalBody>
				<ModalFooter>
					<Button color="primary">Crear Campo Personalizado</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}