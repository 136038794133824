import React from 'react';

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Divider } from 'antd';

const AdminNavbar = (props) => {
	return (	
		<React.Fragment>
			<div id="sidebar-menu">				
				<ul className="metismenu list-unstyled" id="side-menu">
					<li className="menu-title">ADMINISTRADOR</li>
					<li className="mt-2">
						<Link to="/admin/dashboard">
							<i className="bx bx-home"></i>Panel de Control
						</Link>
					</li>
					<Divider className="mb-4" />
					<li>
						<Link to="/admin/contents">
								<i className="bx bx-camera-movie"></i>Contenido
						</Link>
					</li>
					<li>
						<Link to="/admin/products">
							<i className="bx bx-book-heart"></i>Info Productos
						</Link>
					</li>
					<li>
						<Link to="/admin/activities">
							<i className="bx bx-network-chart"></i>Actividades
						</Link>
					</li>
					<Divider className="mb-4" />
					<li>
						<Link to="/admin/users">
							<i className="bx bx-user"></i>Miembros
						</Link>
					</li>
					<Divider className="mb-4" />
					<li>
						<Link to="/admin/settings">
							<i className="bx bx-cog mr-2"></i>Ajustes
						</Link>
					</li>
				</ul>
			</div>
		</React.Fragment>
	);
}

export default withRouter(AdminNavbar);


