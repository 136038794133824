import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { createTask } from '../services'
import { handleError } from '../../../../helpers'

export default function NewTask({ type, contentId, reload }) {
	const { handleSubmit, register, errors, reset } = useForm()

	const onSubmit = values => {	
		values.taskable_type = type
		values.taskable_id = contentId
		createTask(values)
			.then(() => {
				message.success('Reto creado exitosamente.')
				reset()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group>
				<Form.Label>Reto <span className="txt-primary">*</span></Form.Label>
				<Form.Control 
					name="name"
					placeholder="Escriba aquí el Reto"
					ref={register({ required: true })}
				/>
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Texto de Ayuda</Form.Label>
				<Form.Control 
					as="textarea"
					name="description"
					placeholder="Escriba aquí una descripción. Opcional."
					ref={register({})}
				/>
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
					<Form.Control 
						type="integer"
						name="order"
						defaultValue={10}
						ref={register({ required: true })}
					/>
					{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
					<Form.Control 
						as="select"
						name="active"						
						ref={register({})}
					>
						<option value={1}>Publicado</option>
						<option value={0}>Borrador</option>
					</Form.Control>
				</Form.Group>
			</Row>
			<Button color="primary">Crear Reto</Button>
		</Form>
	)
}