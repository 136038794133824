import React from 'react'
import { Card, CardFooter, Button, Col } from 'reactstrap'
import styled from 'styled-components'
import { Divider } from 'antd'
import { Link } from 'react-router-dom'

export default function CourseCard({ course }) {

	if(!course.is_allowed) return null

	return (
		<Col md="4" sm="6" key={course.id}>		
			<Card>
				<Container>
					{ course.logo ? (
						<img src={course.logo} alt="logo" width="210px" />
					) : (
						<Title>{course.name}</Title>
					)}
					<Description>{course.description}</Description>					
				</Container>
				<CardFooter className="bg-white text-center pt-0">
					<Divider className="mt-0 mb-2" />
					{ course.is_allowed ? (
						<Link to={`/courses/${course.id}`}>
							<Button color="primary" outline>Entrar al Curso</Button>
						</Link>
					) : (
						<Button color="primary" outline disabled>Aún no tienes acceso</Button>
					)}
				</CardFooter>
			</Card>
		</Col>
	)
}

export const Container = styled.div`
	padding: 20px 0px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`
export const Title = styled.p`
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	color: #333;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 10px;
	margin-bottom: 0px;
`
export const Description = styled.p`
	text-align: center;
	font-size: 14px;
	color: #555;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 20px;
`