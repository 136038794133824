import React from 'react'
import { Card, CardFooter, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'
import { Divider, Tooltip, Popconfirm } from 'antd'

export default function UserCard({ user, handleEdit, handleDelete, handlePermissions }) {
	return (
		<Card>
			<Container className="p-3">				
				<div className="text-center mt-2">
					<h6 className="mb-0">{user.name}</h6>
					<p className="mb-0 text-muted">{user.email}</p>
					<div className="small text-muted pt-1">
						Creado el: {moment(user.created_at).format('L')}
					</div>
				</div>
			</Container>			
			<CardFooter className="bg-white pt-0">
				<Divider className="mt-0 mb-2" />
				<Row>
					<Col className="text-center" style={{ borderRight: '1px solid #f8f8f8' }}>
						<Tooltip title="Editar">
							<Button color="light" size="sm" onClick={handleEdit}>
								<i className="fa fa-edit" />
							</Button>
						</Tooltip>
					</Col>
					<Col className="text-center" style={{ borderRight: '1px solid #f8f8f8' }}>
						<Tooltip title="Accesos a Contenido">
							<Button color="light" size="sm" onClick={handlePermissions}>
								<i className="fa fa-lock-open" />
							</Button>
						</Tooltip>
					</Col>					
					<Col className="text-center">
						<Tooltip title="Eliminar">
							<Popconfirm
								title="¿Seguro desea elimianar la clase?"
								okText="Eliminar"
								okButtonProps={{ danger: true }}
								cancelText="Cancelar"
								onConfirm={handleDelete}
							>
								<Button color="light" size="sm">
									<i className="fa fa-trash" />
								</Button>
							</Popconfirm>
						</Tooltip>
					</Col>
				</Row>
			</CardFooter>
		</Card>
	)
}

export const Container = styled.div`
	padding-top: 20px;
	background-color: #fff;
	height: 100px;
	overflow-y: scroll;
`