import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message, Popconfirm } from 'antd'

import { updateChapter, deleteChapter } from '../services'
import { handleError } from '../../../../helpers'

export default function EditChapter({ visible, onClose, reload, chapter }) {
	const [loading, setLoading] = useState(null)
	const { handleSubmit, register, errors } = useForm()

	const onSubmit = values => {
		setLoading(true)
		updateChapter(chapter.id, values)
			.then(() => {
				message.success('Capítulo actualizado exitosamente')
				setLoading(false)
				reload()
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	const onDelete = () => {
		deleteChapter(chapter.id)
			.then(() => {
				message.success('Capítulo eliminado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	if(!chapter) return null;

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={()=>onClose()}>
				<ModalHeader toggle={()=>onClose()}>Actualizar Capítulo</ModalHeader>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody>
						<Form.Group>
							<Form.Label>Nombre del Capítulo <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								name="name"
								defaultValue={chapter.name}
								ref={register({required:true})}
							/>
							{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Descripción Corta</Form.Label>
							<Form.Control 
								as="textarea"
								name="description"
								defaultValue={chapter.description}
								ref={register({})}
							/>
						</Form.Group>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
								<Form.Control 
									type="number"
									name="order"
									defaultValue={chapter.order}
									ref={register({ required: true })}
								/>
								{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
								<Form.Control
									as="select"
									name="active"
									defaultValue={chapter.active ? 1 : 0}
									ref={register({ required: true })}
								>
									<option value={0}>Borrador</option>
									<option value={1}>Publicado</option>
								</Form.Control>
							</Form.Group>
						</Row>
					</ModalBody>
					<ModalFooter>
						<div className="float-left">
							<Popconfirm
								title="Eliminar el Capítulo"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger: true }}
								onConfirm={()=>onDelete()}
							>
								<Button color="danger" outline>
									<i className="fa fa-trash" /> Eliminar Capítulo
								</Button>
							</Popconfirm>
						</div>
						<Button color="primary" disabled={loading}>
							Actualizar Capítulo {loading && <Spinner size="sm" />}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</React.Fragment>
	)
}