import React, {useState, useEffect} from 'react'
import { Button, Spinner } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { Empty, Tooltip } from 'antd'

import NoDataImg from '../../../../assets/images/undraw/nodata.svg'
import { getModules } from '../services'
import { handleError } from '../../../../helpers'
import NewModule from './NewModule'
import EditModule from './EditModule'

export default function CourseModules({ courseId, reload, handleSelect }) {
	const [modules, setModules] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [editModule, setEditModule] = useState(null)

	useEffect(() => {
		!modules	&& getModules({ "filter[course_id]": courseId })
			.then(res => setModules(res.data.data))
			.catch(error => handleError(error))
	}, [modules, courseId])

	if(!modules) return <Spinner />

	return (
		<React.Fragment>
			{ modules.length > 0 ? (
				<React.Fragment>
					{ modules.map(module => (
						<InputGroup key={module.id} className="mb-2">
							<InputGroup.Prepend>
								<InputGroup.Text className={module.active ? 'bg-white' : 'bg-light'}>{module.order}</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control 
								disabled 
								value={`${!module.active ? '[Borrador] ' : ''}${module.name}`} 
								className={module.active ? '' : 'bg-light'}
							/>
							<InputGroup.Append>
								<Tooltip title="Editar">
									<Button color="primary" outline onClick={()=>setEditModule(module)}>
										<i className="fa fa-edit" />
									</Button>
								</Tooltip>
							</InputGroup.Append>
							<InputGroup.Append>
								<Tooltip title="Capítulos">
									<Button 
										color="primary" 										
										outline
										onClick={()=>handleSelect(module)} 
									>
										<i className="fa fa-boxes" />
									</Button>
								</Tooltip>
							</InputGroup.Append>
						</InputGroup>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No tiene módulos creados aún"
					image={NoDataImg}
				/>
			)}
			<div className="text-center mt-4">
				<Button color="primary" onClick={()=>setShowNewModal(true)}>Agregar Módulo</Button>
			</div>
			<NewModule 
				visible={showNewModal}
				onClose={()=>setShowNewModal(false)}
				reload={()=>{ setShowNewModal(false); setModules(null) }}
				courseId={courseId}
			/>
			<EditModule 
				visible={editModule ? true : false}
				onClose={()=>setEditModule(null)}
				reload={()=>{ setEditModule(null); setModules(null); reload() }}
				module={editModule}
			/>
		</React.Fragment>
	)
}