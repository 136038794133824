import React from 'react'
import { Card, CardBody, Badge, Button } from 'reactstrap'
import { Table, Tooltip, Divider, Popconfirm } from 'antd'
import moment from 'moment'

export default function LessonsTable({ lessons, handleEdit, handleDelete }) {
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => (
				<Badge className="mb-2" color={active ? 'success' : 'warning'}>
					{active ? 'Publicada' : 'Borrador'}
				</Badge>
			)
		},
		{
			title: 'Creada el',
			dataIndex: 'created_at',
			render: date => moment(date).format('L')
		},
		{
			title: 'Actualizada el',
			dataIndex: 'created_at',
			render: date => moment(date).format('L')
		},
		{
			title: 'Acciones',
			render: (text, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button color="light" size="sm" onClick={() => handleEdit(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea elimianar la clase?"
							okText="Eliminar"
							okButtonProps={{ danger: true }}
							cancelText="Cancelar"
							onConfirm={() => handleDelete(record.id)}
						>
							<Button color="light" size="sm">
								<i className="fa fa-trash" />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<Card>
			<CardBody>
				<Table 
					columns={columns}
					dataSource={lessons}
					rowKey={record => record.id}
					expandable={{
						expandedRowRender: record => (
							<p style={{ margin: 0 }}>
								<strong>Descripción:</strong>{" "}
								{record.description ? record.description : 'No tiene descripción'}
							</p>
						)
					}}
				/>
			</CardBody>
		</Card>
	)
}