import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import { getNotes } from '../Admin/Notes/services'
import { handleError } from '../../helpers'
import SearchBar from '../../components/SearchBar'
import { Link } from 'react-router-dom'

export default function UserNotes({ user }) {
	const [notes, setNotes] = useState(null)
	const [filterType, setFilterType] = useState('note')
	const [filterTerm, setFilterTerm] = useState('')

	useEffect(()=>{
		!notes && getNotes({
			include: "content",
			"filter[user_id]": user.id,
			[`filter[${filterType}]`]: filterTerm
		})
			.then(res => setNotes(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<SearchBar 
				type={filterType} setType={setFilterType} 
				term={filterTerm} setTerm={setFilterTerm} 
				handleSearch={()=>setNotes(null)}
			>
				<option value="note">Buscar en la nota: </option>
			</SearchBar>
			<NotesContainer>
				{ notes ? (
					<React.Fragment>
						{ notes.length > 0 ? (
							<React.Fragment>
								{ notes.map(note => (
									<NoteCard key={note.id}>
										<p className="small mb-1 text-muted">Actualizada el: {moment(note.updated_at).format('L')}</p>
										<p className="mb-2">{note.note}</p>
										<h6 className="small">
											{note.content.content.name}
											<Link to={`/${note.content.content_type}/${note.content.id}`} className="ml-2">Ver Clase</Link>
										</h6>
									</NoteCard>
								)) }
							</React.Fragment>
						) : <Empty description="No se encontron notas" /> }
					</React.Fragment>
				) : <Spinner size="sm" /> }
			</NotesContainer>
			{ notes && notes.length > 0 && (
				<div className="text-center small text-muted mt-2">
					Mostrando un total de: {notes.length} nota{notes.length > 1 ? 's' : ''}
				</div>
			)}
		</React.Fragment>
	)
}

const NotesContainer = styled.div`
	max-height: 400px;
	overflow-y: scroll;
	background-color: #f9f9fc;
	padding: 15px;
	margin: 10px 0px;
`
const NoteCard = styled.div`
	background-color: #fff7d0;
	padding: 10px 10px 5px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	margin-bottom: 10px;
`