import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import LessonsList from './LessonsList'

export default function Lessons() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/admin/lessons" component={LessonsList} />

				<Redirect to="404" />
			</Switch>
		</React.Fragment>
	)
}