import React from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import { Table, Tooltip, Divider, Popconfirm } from 'antd'
import moment from 'moment'

export default function UsersTable({ users, handleEdit, handleDelete, handlePermissions }) {
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email'
		},
		{
			title: 'Creado el',
			dataIndex: 'created_at',
			render: date => moment(date).format('L')
		},
		{
			title: 'Acciones',
			render: (text, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button color="light" size="sm" onClick={() => handleEdit(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Accesos a Contenido">
						<Button color="light" size="sm" onClick={() => handlePermissions(record)}>
							<i className="fa fa-lock-open" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea eliminar el usuario?"
							okText="Eliminar"
							okButtonProps={{ danger: true }}
							cancelText="Cancelar"
							onConfirm={() => handleDelete(record.id)}
						>
							<Button color="light" size="sm">
								<i className="fa fa-trash" />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<Card>
			<CardBody>
				<Table 
					columns={columns}
					dataSource={users}
					rowKey={record => record.id}
				/>
			</CardBody>
		</Card>
	)
}