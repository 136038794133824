import React, { useState, useEffect } from 'react'
import { Spinner, Button } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import { getAttachments } from '../Admin/Attachments/services'
import { handleError } from '../../helpers'

export default function AttachmentsTab({ content }) {
	const [attachments, setAttachments] = useState(null)

	useEffect(()=>{
		!attachments && getAttachments({ 
			"filter[attachable_type]": content.content_type,
			"filter[attachable_id]": content.content_id,
			"filter[active]": 1
		})
			.then(res => setAttachments(res.data.data))
			.catch(error => handleError(error))
	}, [content, attachments])

	if(!attachments) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ attachments.length > 0 ? (
				<React.Fragment>
					{ attachments.map( (attachment, index) => (
						<AttachmentDiv key={attachment.id}>
							<h6>{attachment.name}</h6>
							<p className="text-muted small mb-2">{attachment.description}</p>
							{ attachment.link && (
								<a href={attachment.link} target="_blank" rel="noopener noreferrer">
									<Button color="primary" size="sm">Abrir Enlace</Button>
								</a>
							)}
							{ attachment.file_url && (
								<a href={attachment.file_url} target="_blank" rel="noopener noreferrer">
									<Button color="primary" size="sm">Abrir Archivo</Button>
								</a>
							)}
						</AttachmentDiv>
					)) }
				</React.Fragment>
			) : <Empty description="No hay descargables para este contenido" />}
		</React.Fragment>
	)
}

const AttachmentDiv = styled.div`
	background-color: #ebedf2;
	padding: 15px;
	margin-bottom: 10px;
`