import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

const PageTitle = ({ title, directory, children }) => (
    <React.Fragment>
        <Row>
            <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">{title}</h4>
                    { children ? (
                        <div className="page-title-right">
                            {children}
                        </div>
                    ) : (
                        <div className="page-title-right hide-sm">
                            <Breadcrumb listClassName="m-0">
                                <BreadcrumbItem>
                                    <Link to="#">{directory}</Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <Link to="#">{title}</Link>
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    </React.Fragment>
)

export default PageTitle;