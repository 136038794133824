import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { createCourse } from '../services'
import { handleError } from '../../../../helpers'
import { message } from 'antd'

export default function NewCourse({ visible, onClose, reload }) {
	const { handleSubmit, register, errors } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		createCourse(values)
			.then(() => {
				message.success('Curso creado exitosamenente.')
				setLoading(false)
				reload()
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<Modal isOpen={visible} toggle={()=>onClose()}>
			<ModalHeader toggle={()=>onClose()}>Crear Curso Nuevo</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<Form.Group>
						<Form.Label>Nombre del Curso <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							name="name"
							placeholder="Escribe un nombre para el curso"
							ref={register({ required: true })}
						/>
						{ errors.name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Descripción del Curso <span className="txt-primary">*</span></Form.Label>
						<Form.Control 
							as="textarea"
							name="description"
							placeholder="Escribe una descripción para el curso"
							ref={register({ required: true })}
						/>
						{ errors.description && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>URL Logo del Curso</Form.Label>
						<Form.Control 
							name="logo"
							type="url"
							placeholder="Ingrese una url válida"
							ref={register({})}
						/>
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						Crear Curso {loading && <Spinner size="sm" className="ml-2" /> }
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}