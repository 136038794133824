import s4lmsapi from "../../../services/s4lmsapi";

export const getSolutions = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/solutions`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getSolution = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/solutions/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createSolution = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/solutions', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateSolution = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/solutions/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteSolution = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/solutions/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)