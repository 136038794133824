import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardBody, Spinner } from 'reactstrap'
import { Table, Empty } from 'antd'
import moment from 'moment'

import EmptyImg from '../../../../assets/images/undraw/nodata.svg'
import SearchBar from '../../../../components/SearchBar'

import { getLogs } from '../../Logs/services'
import { handleError, parsePagination } from '../../../../helpers'
import Pagination from '../../../../components/Pagination'

export default function CompletedLog() {
	const [logs, setLogs] = useState(null)
	const [filterType, setFilterType] = useState('user.name')
	const [filterTerm, setFilterTerm] = useState('')
	const [pagination, setPagination] = useState({ page: 1, per_page: 15, total: 0 })

	useEffect(()=>{
		!logs && getLogs({
			"filter[type]": 'content_completed',
			[`filter[${filterType}]`]: filterTerm,
			sort: "-created_at",
			include: 'user,content',
			...pagination
		})
			.then(res => {
				setLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [logs, filterTerm, filterType, pagination])	

	return (
		<React.Fragment>
			<Card className="lesson-video-container">
				<CardHeader>
					<i className="fa fa-thumbs-up mr-2 txt-primary" />
					Registro de Clases Completadas{" "}
					<small className="text-muted">
						<em>Todos los registros</em>
					</small>
				</CardHeader>
				<CardBody>
					<SearchBar 
						type={filterType} setType={setFilterType} 
						term={filterTerm} setTerm={setFilterTerm} 
						handleSearch={()=>setLogs(null)}
					>
						<option value="user.name">Buscar en nombre: </option>
						<option value="user.email">Buscar en email: </option>
					</SearchBar>
					{ logs ? (
						<React.Fragment>
							{ logs.length > 0 ? (
								<React.Fragment>
									<Table
										className="mt-3"
										size="small"
										columns={columns}
										dataSource={logs}
										rowKey={record => record.id}
										pagination={false}
									/>
									<Pagination 
										pagination={pagination}
										updatePagination={setPagination}
										reload={() => setLogs(null)}
									/>
								</React.Fragment>
							) : (
								<Empty 
									className="mt-4"
									image={EmptyImg}
									description="No se encontraron resultados"
								/>
							)}
						</React.Fragment>
					) : <Spinner />}
				</CardBody>				
			</Card>
		</React.Fragment>
	)
}

const columns = [
	{
		title: 'Clase',
		dataIndex: 'content',
		render: (text, record) => (
			<Link to={`/${record.content.content_type}/${record.content.id}`}>
				{text.name} <small>[{record.content.product_name}]</small>
			</Link>)
	},
	{
		title: 'Usuario',
		dataIndex: 'user',
		render: text => text.name
	},
	{
		title: 'Finalizada El',
		dataIndex: 'payload',
		render: text => moment(text.completedAt).format('YYYY-MM-DD h:mm A')
	}
]