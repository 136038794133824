import s4lmsapi from "../../../services/s4lmsapi";

export const getNotes = params => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/notes`, { params })
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const getNote = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.get(`/notes/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const createNote = data => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.post('/notes', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const updateNote = (id, data) => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.put(`/notes/${id}`, data)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)

export const deleteNote = id => (
	new Promise((resolve, reject) => {
		s4lmsapi()
			.delete(`/notes/${id}`)
				.then(response => resolve(response))
				.catch(error => reject(error))
	})
)