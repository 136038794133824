import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Redirect, Route } from 'react-router-dom'

import Layout from "../../layouts/SideLayout"
import CommunityProfiles from './CommunityProfiles'

export default function Community() {
	const settings = useSelector(state => state.settings)

	return (
		<Layout>
			<Switch>
				{ settings.community_enable && (
					<Route exact path="/community" component={CommunityProfiles} />
				)}

				<Redirect to="404" />
			</Switch>
		</Layout>
	)
}