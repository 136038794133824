import React, {useState} from 'react'
import { Spinner, Badge, Button } from 'reactstrap'
import { Table, Empty, Tooltip, Popconfirm, Divider, message } from 'antd'

import EditAttachment from './EditAttachment'
import EmptyImg from '../../../../assets/images/undraw/nodata.svg'
import { deleteAttachment } from '../services'
import { handleError } from '../../../../helpers'
import UploadFileModal from './UploadFileModal'

export default function AttachmentsTable({ attachments, reload }) {
	const [editAttachment, setEditAttachment] = useState(null)
	const [attachmentModal, setAttachmentModal] = useState(null)

	if(!attachments) return <Spinner />

	const handleDelete = id => {
		deleteAttachment(id)
			.then(() => {
				message.success('Recurso eliminado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order',
			width: 80
		},
		{
			title: 'Tipo',
			dataIndex: 'link',
			render: link => link ? 'Link Externo' : 'Descargable'
		},
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => (
				<Badge className="mb-2" color={active ? 'success' : 'warning'}>
					{active ? 'Publicada' : 'Borrador'}
				</Badge>
			)
		},
		{
			title: 'Acciones',
			render: (text, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button color="light" size="sm" onClick={() => setEditAttachment(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea elimianar la pregunta?"
							okText="Eliminar"
							okButtonProps={{ danger: true }}
							cancelText="Cancelar"
							onConfirm={() => handleDelete(record.id)}
						>
							<Button color="light" size="sm">
								<i className="fa fa-trash" />
							</Button>
						</Popconfirm>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Archivo">
						<Button color="light" size="sm" onClick={() => setAttachmentModal(record)}>
							<i className="fa fa-upload" />
						</Button>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>						
			{ attachments.length > 0 ? (
				<Table 
					columns={columns}
					dataSource={attachments}
					rowKey={record => record.id}
					expandable={{
						expandedRowRender: record => (
							<React.Fragment>							
								<p style={{ margin: 0 }}>
									<strong>Descripción:</strong>{" "}
									{record.description ? record.description : 'No tiene descripción'}
								</p>
								{record.link && (
									<p style={{ margin: 0 }}>
										<strong>Link Externo:</strong>{" "}
										<a href={record.link} target="_blank" rel="noopener noreferrer">{record.link}</a>
									</p>
								)}
							</React.Fragment>
						)
					}}
				/>
			) : (
				<Empty 
					description="No tienes preguntas creadas para este contenido."
					image={EmptyImg}
				/>
			)}
			<EditAttachment 
				visible={editAttachment ? true : false}
				onClose={() => setEditAttachment(null)}
				reload={() => {
					setEditAttachment(null)
					reload()
				}}
				attachment={editAttachment}
			/>
			<UploadFileModal 
				visible={attachmentModal ? true : false}
				onClose={()=>setAttachmentModal(null)}
				attachment={attachmentModal}
				reload={reload}
			/>
		</React.Fragment>
	)
}