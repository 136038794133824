import React, { useState } from 'react';
import {useSelector} from "react-redux"
import { Link } from "react-router-dom";

import ProfileMenu from "./TopbarDropdown/ProfileMenu";

const Header = ({ isMobile, toggleMenuCallback }) => {  
  const [term, setTerm] = useState(null)
  const settings = useSelector(state => state.settings)

  const handleSearch = () => {
		if(term) window.location.href = `/search/${encodeURI(term.toLowerCase())}`
	}

  return (
    <React.Fragment>
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">

            <Link to="/" className="logo">
              <span className="logo-sm">
                { settings.app_logo ? (
                  <img src={settings.app_logo} alt="Logo" width="120" />
                ) : (
                  settings.app_name ? settings.app_name : 'Smart4 eLearning'
                )}
              </span>
              <span className="logo-lg">
                { settings.app_logo ? (
                  <img src={settings.app_logo} alt="Logo" width="120" />
                ) : (
                  settings.app_name ? settings.app_name : 'Smart4 eLearning'
                )}
              </span>
            </Link>
          </div>

          <button type="button" onClick={() => toggleMenuCallback()} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars"></i>
          </button>

          <div className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input 
                type="text" 
                className="form-control" 
                placeholder="Buscar contenido..." 
                value={term ? term : ''}
								onChange={e => setTerm(e.target.value)}
								onKeyDown={e => e.key === 'Enter' && handleSearch()}
              />
              <span className="bx bx-search-alt cursor-pointer" onClick={()=>handleSearch()} />
            </div>
          </div>

        </div>
        <div className="d-flex">

          <ProfileMenu />

        </div>
      </div>
    </header>
  </React.Fragment>
  );
}

export default Header;
