import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import SearchEntity from '../../../../components/SearchEntity'
import { createContent } from '../services'
import { handleError } from '../../../../helpers'

export default function NewContent({ visible, onClose, reload, productType, productId }) {
	const { handleSubmit, register, errors } = useForm()
	const [contentType, setContentType] = useState("lessons")
	const [contentId, setContentId] = useState(null)
	const [nextId, setNextId] = useState(null)

	const onSubmit = values => {
		values.product_type = productType
		values.product_id = productId	
		createContent(values)
			.then(() => {
				message.success('Contenido asociado exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Asociar Contenido</ModalHeader>
			<Form onSubmit={handleSubmit(onSubmit)}> 
				<ModalBody>					
					<Form.Group>
						<Form.Label>Tipo de Contenido <span className="txt-primary">*</span></Form.Label>
						<Form.Control
							as="select"
							name="content_type"
							value={contentType}
							onChange={e => setContentType(e.target.value)}
							ref={register({})}
						>
							<option value="lessons">Clase en Video</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>Contenido <span className="txt-primary">*</span></Form.Label>
						<SearchEntity 
							style={{ width: '100%' }}
							resourceType={contentType}
							setResourceId={setContentId}
						/>
						<Form.Text className="text-muted">Busque el contenido por nombre</Form.Text>
						<input type="hidden" value={contentId ? contentId : ''} name="content_id" ref={register({ required: true })} />
						{ errors.content_id && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Próximo Contenido</Form.Label>
						<SearchEntity 
							style={{ width: '100%' }}
							resourceType="contents"
							setResourceId={setNextId}
						/>
						<Form.Text className="text-muted">Busque el contenido por nombre</Form.Text>
						<input type="hidden" value={nextId ? nextId : ''} name="next_id" ref={register({})} />
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Orden <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								type="integer"
								name="order"
								defaultValue={10}
									ref={register({ required: true })}
								/>
								{ errors.order && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Estado <span className="txt-primary">*</span></Form.Label>
							<Form.Control 
								as="select"
								name="active"						
								ref={register({})}
							>
								<option value={1}>Publicada</option>
								<option value={0}>Borrador</option>
							</Form.Control>
						</Form.Group>
					</Row>					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit">Asociar Contenido</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)
}