import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Divider, Tooltip, Popconfirm } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import { getNotes, createNote, deleteNote, updateNote } from '../Admin/Notes/services'
import { handleError } from '../../helpers'

export default function NotesTab({ contentId }) {
	const auth = useSelector(state => state.auth)
	const { handleSubmit, register, errors, reset } = useForm()
	const [notes, setNotes] = useState(null)
	const [editNote, setEditNote] = useState(null)

	useEffect(()=>{
		!notes && getNotes({ 
			"filter[content_id]": contentId,
			"filter[user_id]": auth.user.id
		})
			.then(res => setNotes(res.data.data))
			.catch(error => handleError(error))
	})

	const onSubmit = values => {
		values.content_id = contentId
		values.user_id = auth.user.id
		createNote(values)
			.then(() => {
				setNotes(null)
				reset()
			})
			.catch(error => handleError(error))
	}

	const onEditSubmit = values => {
		updateNote(editNote.id, values)
			.then(() => {
				setEditNote(null)
				setNotes(null)
				reset()
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteNote(id)
			.then(() => setNotes(null))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ !editNote ? (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Control
						as="textarea"
						name="note"
						defaultValue=""
						rows={3}
						ref={register({ required: true })}
					/>
					{ errors.note && <Form.Text className="text-warning">Debes escribir una nota para guardar</Form.Text> }
					<Button color="primary" className="full-width mt-2" size="sm" outline>Guardar Nota</Button>
				</Form>
			) : (
				<Form onSubmit={handleSubmit(onEditSubmit)}>					
					<Form.Control
						as="textarea"
						name="note"
						rows={3}
						defaultValue={editNote.note}
						ref={register({ required: true })}
					/>
					{ errors.note && <Form.Text className="text-warning">Debes escribir una nota para guardar</Form.Text> }
					<Button color="primary" className="full-width mt-2" size="sm" outline>Actualizar Nota</Button>
				</Form>
			)}
			<Divider className="my-2" />
			<NotesContainer>
				{ notes && notes.map(note => (
					<NoteDiv key={note.id}>
						<div>
							<div className="float-right mr-2">
								<Tooltip title="Editar">
									<i className="fa fa-edit cursor-pointer" onClick={()=>setEditNote(note)} />
								</Tooltip>
								<Divider type="vertical" />
								<Tooltip title="Eliminar">
									<Popconfirm
										title="¿Seguro deseas eliminar?"
										okText="Eliminar"
										okButtonProps={{ danger: true }}
										cancelText="Cancelar"
										onConfirm={()=>handleDelete(note.id)}
										placement="left"
									>
										<i className="fa fa-trash" />
									</Popconfirm>
								</Tooltip>
							</div>
							<span className="small">{moment(note.created_at).format('L')}</span>
						</div>
						{note.note}
					</NoteDiv>
				))}
			</NotesContainer>
		</React.Fragment>
	)
}

const NotesContainer = styled.div`
	max-height: 200px;
	overflow-y: scroll;
`

const NoteDiv = styled.div`
	background-color: #f9f9fc;
	padding: 10px;
	margin-bottom: 10px;
`