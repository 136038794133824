import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import PageTitle from '../../../components/PageTitle'
import TutorialImg from '../../../assets/images/undraw/tutorial.svg'
// import ResearchingImg from '../../../assets/images/undraw/researching.svg'

export default function ProductsCatalog() {

	const products = [
		{
			image: TutorialImg,
			title: 'Cursos Online',
			description: 'Cursos medianos y grandes. Divididos por módulos y capítulos.',
			btnText: 'Ver mis cursos online',
			btnLink: '/admin/courses'
		},
		// {
		// 	image: ResearchingImg,
		// 	title: 'Mini Curso',
		// 	description: 'Cursos cortos con acceso rápido al contenido. Sin módulos ni capítulos.',
		// 	btnText: 'Ver mis cursos cortos',
		// 	btnLink: '/admin/courses'
		// },
	]

	return (
		<React.Fragment>
			<PageTitle title="Info Productos" directory="Administrador" />
			<Row>
				{ products.map((product,i) => (
					<Col md="4" lg="3" key={i}>
						<Container>
							<img src={product.image} alt={product.title} width="70%" />
							<h4 className="mt-2">{product.title}</h4>
							<Description>{product.description}</Description>
							<Link to={product.btnLink}>
								<Button color="primary" size="sm">{product.btnText}</Button>
							</Link>
						</Container>
					</Col>
				)) }
			</Row>
		</React.Fragment>
	)
}

export const Container = styled.div`
	background-color: #fff;
	width: 100%;
	height: 300px;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 20px;
`
export const Description = styled.p`
	text-align: center;
	font-size: 11px;
	color: #555;
`