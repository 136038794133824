import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardHeader, CardBody, Spinner, Button } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message } from 'antd'

import { getSettings, createSetting } from '../services'
import { handleError } from '../../../../helpers'

export default function AccountSettings() {
	const { handleSubmit, register, errors } = useForm()
	const [settings, setSettings] = useState(null)

	useEffect(()=>{
		!settings && getSettings()
			.then(res => setSettings(res.data.data))
			.catch(error => handleError(error))
	}, [settings])

	const onSubmit = async values => {
		for (const [key, value] of Object.entries(values)) {
			if(value)
				await createSetting({ key, value })
					.catch(error => handleError(error))
		}
		
		message.success('Ajustes guardados exitosamente')
		window.location.reload()
	}

	return (
		<React.Fragment>
			<Card>
				<CardHeader>Personalizar la Cuenta</CardHeader>
				<CardBody>
					{ settings ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>Nombre de App</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										name="app_name"
										defaultValue={settings.app_name ? settings.app_name : ''}
										placeholder="Escribe el nombre de la empresa"
										ref={register({ required:true })}
									/>
								</InputGroup>
								{ errors.app_name && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>URL Logo App</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										name="app_logo"
										defaultValue={settings.app_logo ? settings.app_logo : ''}
										placeholder="Escribe la url del logo de la empresa"
										ref={register({ })}
									/>
								</InputGroup>
								{ errors.app_logo && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>URL Favicon</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										name="app_favicon"
										defaultValue={settings.app_favicon ? settings.app_favicon : ''}
										placeholder="Escribe la url del favicon del sitio web"
										ref={register({ })}
									/>
								</InputGroup>
								{ errors.app_favicon && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>Color Principal</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										name="primary_color"
										defaultValue={settings.primary_color ? settings.primary_color : ''}
										placeholder="Escribe el color principal de la app"
										ref={register({ required:true })}
									/>
								</InputGroup>
								{ errors.primary_color && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>URL Video de Bienvenida</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										name="welcome_video"
										defaultValue={settings.welcome_video ? settings.welcome_video : ''}
										placeholder="Escribe la url del video de bienvenida"
										ref={register({ })}
									/>
								</InputGroup>
								{ errors.welcome_video && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>Habilitar Comunidad</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										as="select"
										name="community_enable"
										defaultValue={settings.community_enable === "1" ? 1 : 0}
										ref={register({ })}
									>
										<option value={0}>Ocultar</option>
										<option value={1}>Habilitar</option>
									</Form.Control>
								</InputGroup>
							</Form.Group>
							<Form.Group>
								<InputGroup>
									<InputGroup.Append>
										<InputGroup.Text>Mostrar Emails en Comunidad</InputGroup.Text>
									</InputGroup.Append>
									<Form.Control 
										as="select"
										name="community_allow_email"
										defaultValue={settings.community_allow_email === "1" ? 1 : 0}
										ref={register({ })}
									>
										<option value={0}>Ocultar correos y no permitir contacto</option>
										<option value={1}>Mostrar correos y permitir contactarse</option>
									</Form.Control>
								</InputGroup>
							</Form.Group>
							<Button color="primary" className="mt-3">Guardar Ajustes</Button>
						</Form>
					) : <Spinner />}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}