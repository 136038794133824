import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Card, CardBody, Row, Col } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import EmptyImg from '../../assets/images/undraw/researching.svg'
import { getUsers } from '../Admin/Users/services'
import { handleError } from '../../helpers'
import ProfileDetails from './partials/ProfileDetails'
import ProfileCard from './partials/ProfileCard'
import SearchBar from '../../components/SearchBar'

export default function CommunityProfiles() {
	const [users, setUsers] = useState(null)
	const auth = useSelector(state => state.auth)
	const [showUserDetails, setShowUserDetails] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterTerm, setFilterTerm] = useState('')

	useEffect(()=>{
		!users && getUsers({
			// "filter[is_admin]":0,
			include: 'customValues',
			[`filter[${filterType}]`]: filterTerm
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users, auth, filterTerm, filterType])

	return (
		<React.Fragment>
			<PageTitle title="Perfiles" directory="Comunidad" />
			<Card>
				<CardBody className="full-width">
					<SearchBar
						type={filterType} setType={setFilterType} 
						term={filterTerm} setTerm={setFilterTerm} 
						handleSearch={()=>setUsers(null)}
					>
						<option value="name">Buscar por nombre: </option>
						<option value="email">Buscar por correo: </option>
					</SearchBar>
				</CardBody>
			</Card>
			{ users ? (
				<Row>
					{ users.length > 0 ? (
						<React.Fragment>
							{ users.map(user => (
								<Col md="4" key={user.id}>
									<ProfileCard 
										user={user} 
										onDetails={record => setShowUserDetails(record)} 
									/>
								</Col>
							)) }
							<ProfileDetails 
								visible={showUserDetails ? true : false}
								onClose={()=>setShowUserDetails(null)}
								user={showUserDetails}
							/>
						</React.Fragment>
					) : (		
						<Col md="4">
							<Card>
								<CardBody className="my-2">
									<Empty image={EmptyImg} description="Aún se encontraron perfiles creados" />
								</CardBody>
							</Card>
						</Col>				
					)}
				</Row>
			) : (
				<div className="text-center mt-4">
					<Spinner />
				</div>
			)}
		</React.Fragment>
	)
}

export const UserDetails = styled.div`
	height: ${props => props.scroll ? '60px' : 'auto'};
	overflow-y: scroll;
	background-color: #f8f8f8;
	padding: 10px 10px 5px;
	margin-top: 15px;
`
export const UserDetail = styled.p`
	margin-bottom: 5px;
`