import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Spinner, Badge } from 'reactstrap'
import styled from 'styled-components'

import s4lmsapi from '../../services/s4lmsapi'
import { handleError } from '../../helpers'

export default function ProductContents({ productType, productId, currentContentId }) {
	const [product, setProduct] = useState(null)
	const [currentProductKey, setCurrentProductKey] = useState(productType+'.'+productId)

	useEffect(()=>{
		!product && s4lmsapi()
			.get(`/${productType}/${productId}`, { })
				.then(res => setProduct(res.data.data))
				.catch(error => handleError(error))
	}, [product, productType, productId])

	if(currentProductKey !== productType+'.'+productId) {
		setCurrentProductKey(productType+'.'+productId)
		setProduct(null)
	}

	if(!product) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ product.contents.map( (content, i) => (
				<Link key={content.id} to={`/${content.content_type}/${content.id}`}>
					<Container active={ content.id === currentContentId ? true : false }>
						<Badge size="sm" color={content.is_completed ? 'success' : 'warning'} className="mr-2">
							{content.is_completed ? 'Completada' : 'Pendiente'}
						</Badge>
						<small>Clase {i+1}:</small>
						<p className="mb-0 mt-1">{content.name}</p>
					</Container>
				</Link>
			)) }
		</React.Fragment>
	)
}

const Container = styled.div`
	background-color: ${props => props.active ? '#ebedf2' : '#f9f9fc'};
	color: #222;
	padding: 10px;
	margin-bottom: 10px;
	&:hover {
		background-color: #ebedf2;
	}
`