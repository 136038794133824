import axios from "axios";
import {store} from "../store";

/*
 * Instancia con el servicio principal del backend
 */
const s4lmsapi = () => {
   const state = store.getState();
   const authToken = state.auth.token;
   
   var url = window.location.host.split('.')

   let domain = ''

   if(url[1].includes('localhost')){
      domain = `https://${url[0]}.s4lms_api.test/api`
   } else if(url[1].includes('smart4elearning')) {
      domain = `https://${url[0]}.api.smart4elearning.com/api`
   } else {
      domain = `https://${url[1]}.api.smart4elearning.com/api`
   }

   return axios.create({
      baseURL: domain,
      timeout: 10000,
      headers: {
         Authorization: `Bearer ${authToken}`,
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default s4lmsapi;
