import React, {useState} from 'react'
import { Spinner, Badge, Button } from 'reactstrap'
import { Table, Empty, Tooltip, Popconfirm, Divider, message } from 'antd'

import EditTask from './EditTask'
import EmptyImg from '../../../../assets/images/undraw/nodata.svg'
import { deleteTask } from '../services'
import { handleError } from '../../../../helpers'

export default function TasksTable({ tasks, reload }) {
	const [editTask, setEditTask] = useState(null)

	if(!tasks) return <Spinner />

	const handleDelete = id => {
		deleteTask(id)
			.then(() => {
				message.success('Reto eliminado exitosamente.')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order',
			width: 80
		},
		{
			title: 'Reto',
			dataIndex: 'name'
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: active => (
				<Badge className="mb-2" color={active ? 'success' : 'warning'}>
					{active ? 'Publicada' : 'Borrador'}
				</Badge>
			)
		},
		{
			title: 'Acciones',
			render: (text, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button color="light" size="sm" onClick={() => setEditTask(record)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="¿Seguro desea elimianar el reto?"
							okText="Eliminar"
							okButtonProps={{ danger: true }}
							cancelText="Cancelar"
							onConfirm={() => handleDelete(record.id)}
						>
							<Button color="light" size="sm">
								<i className="fa fa-trash" />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>						
			{ tasks.length > 0 ? (
				<Table 
					columns={columns}
					dataSource={tasks}
					rowKey={record => record.id}
					expandable={{
						expandedRowRender: record => (
							<p style={{ margin: 0 }}>
								<strong>Descripción:</strong>{" "}
								{record.description ? record.description : 'No tiene descripción'}
							</p>
						)
					}}
				/>
			) : (
				<Empty 
					description="No tienes retos creados para este contenido."
					image={EmptyImg}
				/>
			)}
			<EditTask 
				visible={editTask ? true : false}
				onClose={() => setEditTask(null)}
				reload={() => {
					setEditTask(null)
					reload()
				}}
				task={editTask}
			/>
		</React.Fragment>
	)
}