import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Media, ModalFooter, Button } from 'reactstrap'
import moment from 'moment'

import { UserDetails, UserDetail } from '../CommunityProfiles'

export default function ProfileDetails({ visible, onClose, user }) {
	const settings = useSelector(state => state.settings)

	if(!user) return null

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Detalles del Perfil</ModalHeader>
			<ModalBody className="mini-stats-wid">
				<Media>
					<div className="mini-stat-icon avatar-md bkg-primary mr-3">
						{ user.profile_url ? (
							<img src={user.profile_url} alt="Profile" width="100%" />
						):(
							<span className="avatar-title bkg-primary">
								<i className="bx bx-user font-size-24"></i>
							</span>
						)}
					</div>
					<Media body>
						<p className="text-muted small mb-1">
							Miembro desde el{" "}
							{moment(user.created_at).format('L')}
						</p>
						<h4 className="mb-1">{user.name}</h4>
						{ settings.community_allow_email === "1" && (<p className="mb-0 small">{user.email}</p>)}
					</Media>
				</Media>
				<UserDetails scroll={false}>
					{ user.custom_values.map(detail => (							
						<UserDetail key={detail.id}>
							<span className="small text-muted">{detail.formated.name}: </span><br />
							{detail.formated.value}
						</UserDetail>
					))}
				</UserDetails>
			</ModalBody>
			{ settings.community_allow_email === "1" && (
				<ModalFooter>
					<a href={`mailto:${user.email}`}>
						<Button color="primary" outline>Enviar Correo</Button>
					</a>
				</ModalFooter>
			)}
		</Modal>
	)
}