import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Layout from "../../layouts/SideLayout"
import PageTitle from '../../components/PageTitle'
import SearchResults from './SearchResults'

export default function Search() {
	return (
		<Layout>
			<PageTitle title="Resultados de Búsqueda" directory="Buscador" />
			<Switch>
				<Route exact path="/search/:term" component={SearchResults} />

				<Redirect to="/404" />
			</Switch>
		</Layout>
	)
}