import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Divider, Tooltip, Popconfirm } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

import { getComments, createComment, deleteComment, updateComment } from '../Admin/Comments/services'
import { handleError } from '../../helpers'

export default function CommentsTab({ contentId }) {
	const auth = useSelector(state => state.auth)
	const { handleSubmit, register, errors, reset } = useForm()
	const [comments, setComments] = useState(null)
	const [editComment, setEditComment] = useState(null)

	useEffect(()=>{
		!comments && getComments({ 
			"filter[content_id]": contentId,
		})
			.then(res => setComments(res.data.data))
			.catch(error => handleError(error))
	})

	const onSubmit = values => {
		values.content_id = contentId
		values.user_id = auth.user.id
		createComment(values)
			.then(() => {
				setComments(null)
				reset()
			})
			.catch(error => handleError(error))
	}

	const onEditSubmit = values => {
		updateComment(editComment.id, values)
			.then(() => {
				setEditComment(null)
				setComments(null)
				reset()
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteComment(id)
			.then(() => setComments(null))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ !editComment ? (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Control
						as="textarea"
						name="comment"
						defaultValue=""
						rows={3}
						ref={register({ required: true })}
					/>
					{ errors.comment && <Form.Text className="text-warning">Debes escribir una comentario para publicar</Form.Text> }
					<Button color="primary" className="full-width mt-2" size="sm" outline>Publicar Comentario</Button>
				</Form>
			) : (
				<Form onSubmit={handleSubmit(onEditSubmit)}>					
					<Form.Control
						as="textarea"
						name="comment"
						rows={3}
						defaultValue={editComment.comment}
						ref={register({ required: true })}
					/>
					{ errors.comment && <Form.Text className="text-warning">Debes escribir un comentario para guardar</Form.Text> }
					<Button color="primary" className="full-width mt-2" size="sm" outline>Actualizar Commentario</Button>
				</Form>
			)}
			<Divider className="my-2" />
			<CommentsContainer>
				{ comments && comments.map(comment => (
					<CommentDiv key={comment.id}>
						<div>
							<div className="float-right mr-2">
								{ comment.user_id === auth.user.id && (
									<React.Fragment>
										<Tooltip title="Editar">
											<i className="fa fa-edit cursor-pointer" onClick={()=>setEditComment(comment)} />
										</Tooltip>
										<Divider type="vertical" />
										<Tooltip title="Eliminar">
											<Popconfirm
												title="¿Seguro deseas eliminar?"
												okText="Eliminar"
												okButtonProps={{ danger: true }}
												cancelText="Cancelar"
												onConfirm={()=>handleDelete(comment.id)}
												placement="left"
											>
												<i className="fa fa-trash" />
											</Popconfirm>
										</Tooltip>
									</React.Fragment>
								)}							
							</div>
							<span className="small">{moment(comment.created_at).format('L')}</span>
						</div>
						{comment.comment}
						<div className="small">
							<span className="text-muted">{comment.user.name}</span>
						</div>
					</CommentDiv>
				))}
			</CommentsContainer>
		</React.Fragment>
	)
}

const CommentsContainer = styled.div`
	max-height: 200px;
	overflow-y: scroll;
`

const CommentDiv = styled.div`
	background-color: #f9f9fc;
	padding: 10px;
	margin-bottom: 10px;
`